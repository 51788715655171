import React, { useContext, useEffect, useMemo, useState, useCallback, useRef } from "react";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import {
  HistoryTabDataContext,
  ProductEntry,
} from "../../../context/HistoryTabContext";
import { PriceDataContext } from "../../../context/PriceDataContext";
import "./HistoryTab.css";
import {
  IAllHistory,
  IHistoryMessageColumns,
} from "../../../models/history/IProductHistory";
import * as util from "../../../util/util";
import { setToEndOfDay, setToStartOfDay } from "../../../util/util";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Handlebars from "handlebars";
import { Backdrop, CircularProgress } from "@mui/material";
import { fetchHistoryTemplates } from "../../../api/mongoEndpoints";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import DataCell from "./DataCell";

Handlebars.registerHelper('formatPrice', function(price) {
  return typeof price === 'number' ? price.toFixed(3) : price;
});

// Template cache to prevent recompilation
const templateCache = new Map();

const filterTypeMap = {
  proposedPriceHistory: "Proposed Price",
  strategyHistory: "Strategy",
  competitorPriceHistory: "Competitor Price",
  validationHistory: "Controls & Validations",
};

const HistoryTab = () => {
  const { selectedPriceData } = useContext(PriceDataContext);
  const { 
    historyData, 
    setDateRange, 
    isLoading,
    displayCount,
    increaseDisplayCount
  } = useContext(HistoryTabDataContext);

  const [transformedHistory, setTransformedHistory] = useState({});
  const [selectedHistory, setSelectedHistory] = useState<IHistoryMessageColumns[]>([]);
  const [templates, setTemplates] = useState({});
  const [columnSizing, setColumnSizing] = useLocalStorage(
    "historyTab_columnSizing",
    []
  );
  const [columnFilters, setColumnFilters] = useState([]);
  const transformedHistoryRef = useRef({});
  const processedTemplatesRef = useRef(new Map());

  const columns = useMemo<MRT_ColumnDef<IHistoryMessageColumns>[]>(() => {
    const historyTypeCounts = selectedHistory.reduce((acc, item) => {
      acc[item.historyType] = (acc[item.historyType] || 0) + 1;
      return acc;
    }, {});

    const uniqueHistoryTypes = Object.keys(historyTypeCounts);

    return [
      {
        accessorKey: "auditDate",
        header: "Date",
        size: 100,
        enableSorting: true,
        filterVariant: "date-range",
        grow: false,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          let date: Date;

          if (typeof value === "string" || typeof value === "number") {
            date = new Date(value);
          } else if (value instanceof Date) {
            date = value;
          } else {
            console.error("Invalid date value:", value);
            return "";
          }

          return date
            .toLocaleString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
            .replace(/,/g, "");
        },
      },
      {
        accessorKey: "historyType",
        header: "History Type",
        size: 50,
        Cell: ({ renderedCellValue }) => (
          <HistoryTypeCell renderedCellValue={renderedCellValue} />
        ),
        enableSorting: true,
        filterVariant: "multi-select",
        filterSelectOptions: uniqueHistoryTypes.map((type) => ({
          text: `${filterTypeMap[type]} (${historyTypeCounts[type]})`,
          value: type,
        })),
      },
      {
        accessorKey: "userName",
        header: "Init/Approved By",
        enableSorting: true,
        size: 15,
      },
      {
        accessorKey: "message",
        header: "Message",
        enableSorting: false,
        size: 225,
      },
      {
        accessorKey: "data",
        Cell: ({ cell }) => <DataCell data={cell.getValue()} />,
        header: "Data",
        size: 100,
      },
    ];
  }, [selectedHistory]);

  useEffect(() => {
    const loadTemplates = async () => {
      let fetchedTemplates = sessionStorage.getItem("historyTemplates");
      if (fetchedTemplates) {
        console.debug("History templates found in session storage.");
        setTemplates(JSON.parse(fetchedTemplates));
        return;
      }

      console.debug("No history templates found. Fetching from server...");
      try {
        fetchedTemplates = await fetchHistoryTemplates();
        sessionStorage.setItem(
          "historyTemplates",
          JSON.stringify(fetchedTemplates[0])
        );
        setTemplates(fetchedTemplates[0]);
      } catch (error) {
        console.error("Failed to load history templates:", error);
        setTemplates({});
      }
    };
    loadTemplates().then();
  }, []);

  const getCompiledTemplate = useCallback((templateString: string) => {
    if (!templateCache.has(templateString)) {
      templateCache.set(templateString, Handlebars.compile(templateString));
    }
    return templateCache.get(templateString);
  }, []);

  const generateMessageFromTemplate = useCallback((data, messageTemplate) => {
    const compiledTemplate = getCompiledTemplate(messageTemplate);
    return compiledTemplate(data);
  }, [getCompiledTemplate]);

  const getProcessedTemplate = useCallback((data: any, historyType: string) => {
    const cacheKey = `${historyType}-${JSON.stringify(data)}`;
    if (processedTemplatesRef.current.has(cacheKey)) {
      return processedTemplatesRef.current.get(cacheKey);
    }

    const templateInfo = templates[historyType];
    if (!templateInfo) {
      const result = {
        message: "",
        changeType: "N/A",
        data: "",
        userName: "",
      };
      processedTemplatesRef.current.set(cacheKey, result);
      return result;
    }

    let messageTemplate = templateInfo.messageTemplate;
    if (data.strategies === "ManualPrice") {
      console.log('Processing manual price:', {
        raw: data.proposedPrice,
        type: typeof data.proposedPrice,
        formatted: typeof data.proposedPrice === 'number' ? data.proposedPrice.toFixed(3) : data.proposedPrice
      });
      messageTemplate = "Manual price of ${{formatPrice proposedPrice}} set for {{productDisplayName productId}} is {{approveStatus}}";
    }
    if(historyType === 'proposedPriceHistory' && data.approveStatus === 'BLOCKED'){
      if (
          data.controlsAndValidation?.some(
              (validation) =>
                  validation.validationType === "MarketMoveScheduler" &&
                  validation.validationFailed === true
          )
      ) {
        messageTemplate =
            "Proposed price {{productDisplayName productId}} is {{approveStatus}} - Store in Active Market Move";
      } else {
        const failedValidation = data.controlsAndValidation?.find(
            (cv) => cv.validationFailed
        );
        messageTemplate = failedValidation
            ? `Proposed price {{productDisplayName productId}} is {{approveStatus}} - ${failedValidation.validationType} validation FAILED`
            : `Proposed price {{productDisplayName productId}} is {{approveStatus}} - Linked product validation failure`;
      }
    }

    const result = {
      message: generateMessageFromTemplate(data, messageTemplate),
      changeType: getMutationType(data, templateInfo) || "N/A",
      data: templateInfo?.dataTemplate ? generateMessageFromTemplate(data, templateInfo.dataTemplate) : "",
      userName: templateInfo.userField ? generateMessageFromTemplate(data, templateInfo.userField) : "",
    };

    processedTemplatesRef.current.set(cacheKey, result);
    return result;
  }, [templates, generateMessageFromTemplate]);

  const getMutationType = useCallback((data: any, templateInfo: any): string | null => {
    try {
      if (!templateInfo?.changeType) return "N/A";
      
      const changeType = templateInfo.changeType;
      if (changeType.type === "field") {
        const fieldPath = changeType.value.split(".");
        return fieldPath.reduce((acc, field) => acc?.[field], data) || "N/A";
      }
      return changeType.value || "N/A";
    } catch (error) {
      console.error("Failed to get mutation type:", error);
      return "N/A";
    }
  }, []);

  const processHistoryItems = useCallback((
    historyItems: any[],
    historyType: string,
    auditTimeKey: string
  ): ProductEntry[] => {
    return historyItems.map((item) => {
      if (historyType === 'proposedPriceHistory' && item.strategies === "ManualPrice") {
        console.log('Raw history item:', {
          historyType,
          proposedPrice: item.proposedPrice,
          type: typeof item.proposedPrice,
          strategies: item.strategies,
          fullItem: item
        });
      }
      const processedTemplate = getProcessedTemplate(item, historyType);
      return {
        auditDate: (historyType === 'proposedPriceHistory' && item.approveStatus === 'BLOCKED') 
          ? util.parseDate(item['creationDate']) 
          : util.parseDate(item[auditTimeKey]),
        changeType: processedTemplate.changeType.toUpperCase(),
        userName: processedTemplate.userName,
        historyType: historyType,
        message: processedTemplate.message,
        data: processedTemplate.data,
      };
    });
  }, [getProcessedTemplate]);

  const transformHistoryData = useCallback((historyData: IAllHistory) => {
    const transformedData: { [productId: string]: ProductEntry[] } = {};

    Object.entries(historyData.histories).forEach(
      ([productId, productInfo]) => {
        const historyTypes = [
          "proposedPriceHistory",
          "competitorPriceHistory",
          "strategyHistory",
          "validationHistory",
        ];

        const productEntries: ProductEntry[] = [];
        historyTypes.forEach((historyType) => {
          let historyItems = productInfo[historyType];
          if (!historyItems) {
            return;
          }

          if(historyType === 'proposedPriceHistory' ){
            historyItems = historyItems.filter((item) => 
              (templates[historyType]?.dateField && item[templates[historyType].dateField]) || 
              (item.approveStatus=='BLOCKED')
            );
          } else {
            historyItems = historyItems.filter((item) => 
              templates[historyType]?.dateField && item[templates[historyType].dateField]
            );
          }

          try {
            if (templates[historyType]?.dateField) {
              const processedItems = processHistoryItems(
                historyItems,
                historyType,
                templates[historyType].dateField
              );
              if (historyType === "strategyHistory") {
                productEntries.push(...processedItems.filter(
                  (item) => !item.data.includes("UNCHANGED")
                ));
              } else {
                productEntries.push(...processedItems);
              }
            }
          } catch (e) {
            console.error(
              `Failed to process history items for product ID: ${productId} and history type: ${historyType}`,
              e
            );
          }
        });

        // Sort by date descending
        productEntries.sort((a, b) => 
          new Date(b.auditDate).getTime() - new Date(a.auditDate).getTime()
        );

        transformedData[productId] = productEntries;
      }
    );

    return transformedData;
  }, [templates, processHistoryItems]);

  useEffect(() => {
    if (!historyData || !templates) {
      console.debug("No history templates available. Skipping transformation.");
      return;
    }

    // Only transform if data has changed
    const newTransformedHistory = transformHistoryData(historyData);
    if (JSON.stringify(newTransformedHistory) !== JSON.stringify(transformedHistoryRef.current)) {
      transformedHistoryRef.current = newTransformedHistory;
      setTransformedHistory(newTransformedHistory);
    }
  }, [templates, historyData, transformHistoryData]);

  useEffect(() => {
    if (
      !selectedPriceData ||
      !transformedHistory[selectedPriceData.priceData.productId]
    ) {
      console.debug(
        "No price data selected or no transformed history data available for selected product"
      );
      setSelectedHistory([]);
      return;
    }
    
    const allHistory = transformedHistory[selectedPriceData.priceData.productId];
    setSelectedHistory(allHistory.slice(0, displayCount));
  }, [selectedPriceData, transformedHistory, displayCount]);

  useEffect(() => {
    setColumnFilters([]);
    setDateRange({ fromDate: null, toDate: null });
  }, [selectedPriceData]);

  const handleTableScroll = useCallback((event) => {
    const target = event.target;
    const scrollPosition = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    
    // Load more when scrolled past 75% of the table
    if (!isLoading && scrollPosition > (scrollHeight - clientHeight) * 0.75) {
      increaseDisplayCount();
    }
  }, [isLoading, increaseDisplayCount]);

  const handleDateFilterChange = useCallback((newColumnFiltersFn) => {
    let newColumnFilters: { id: string; value: any }[] =
      newColumnFiltersFn(columnFilters);
    if (!newColumnFilters) return;

    let auditDateFilter: Date[] | null[] = newColumnFilters.find(
      (f) => f.id === "auditDate"
    )?.value;

    if (auditDateFilter) {
      let [minDate, maxDate] = auditDateFilter || [null, null];

      const { minDate: newMinDate, maxDate: newMaxDate } = validateDateRange(
        columnFilters.find((f) => f.id === "auditDate")?.value,
        auditDateFilter,
        minDate,
        maxDate
      );

      newColumnFilters = newColumnFilters.map((f) =>
        f.id === "auditDate"
          ? {
              id: "auditDate",
              value: [newMinDate, newMaxDate],
            }
          : f
      );

      setDateRange({ fromDate: newMinDate, toDate: newMaxDate });
    } else {
      setDateRange({ fromDate: null, toDate: null });
    }

    setColumnFilters(newColumnFilters);
  }, [columnFilters, setDateRange]);

  const validateDateRange = useCallback((
    prevAuditDateFilter,
    auditDateFilter,
    minDate,
    maxDate
  ): { minDate: Date; maxDate: Date } => {
    const today = new Date();

    if (minDate && !maxDate) {
      maxDate = setToEndOfDay(today);
    }

    if (!minDate && maxDate) {
      minDate = null;
    }

    if (
      prevAuditDateFilter &&
      prevAuditDateFilter[0] === minDate &&
      prevAuditDateFilter[1] !== maxDate
    ) {
      maxDate = prevAuditDateFilter[1];
    }

    if (minDate && minDate > today) {
      minDate = setToStartOfDay(today);
    }
    if (maxDate && maxDate > today) {
      maxDate = setToEndOfDay(today);
    }

    if (minDate && maxDate && minDate > maxDate) {
      [minDate, maxDate] = [maxDate, minDate];
    }

    return {
      minDate,
      maxDate,
    };
  }, []);

  const table = useMaterialReactTable({
    data: selectedHistory || [],
    columns,
    enablePagination: false,
    enableSorting: true,
    enableFilters: true,
    initialState: {
      sorting: [{ id: "auditDate", desc: true }],
      showColumnFilters: false,
    },
    positionToolbarAlertBanner: "none",
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnActions: true,
    enableHiding: true,
    enableFacetedValues: true,
    columnFilterDisplayMode: "popover",
    enableColumnResizing: true,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: { maxHeight: "53vh" },
      onScroll: handleTableScroll,
    },
    onColumnFiltersChange: handleDateFilterChange,
    state: {
      columnFilters,
      columnSizing,
    },
    onColumnSizingChange: setColumnSizing,
    layoutMode: "grid",
  });

  const isHistoryLoading = useCallback(() => {
    return isLoading || templates === null || historyData === null;
  }, [isLoading, templates, historyData]);

  return (
    <div className="history-tab-inner">
      <Backdrop
        sx={{
          color: "#fff",
          position: "absolute",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isHistoryLoading()}
      >
        {"Loading..."} <CircularProgress color="inherit" />
      </Backdrop>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MaterialReactTable table={table} />
      </LocalizationProvider>
    </div>
  );
};

const HistoryTypeCell = ({ renderedCellValue }) => (
  <>{filterTypeMap[renderedCellValue]}</>
);

export default HistoryTab;
