import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
  QueryClient,
} from "@tanstack/react-query";
import {
  createMarketMove,
  getSingleMMRun,
  updateMarketMove,
  getMarketMoveRunsByRunStatus,
  checkMarketMoveOverlap,
  OverlapCheckRequest,
  MarketMoveOverlapResponse,
} from "../api/marketMoveRunApi";
import { MarketMoveRunSchema } from "../schema/schemas";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import useGeneralStore from "../store/generalStore";
import { formatISODate } from "../utils/textUtils";

export const useCreateMarketMoveRun = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (marketMove: MarketMoveRunSchema) =>
      createMarketMove(marketMove),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ["marketMoveRuns"] });
      queryClient.invalidateQueries({ queryKey: ["marketMoves"] });
      queryClient.invalidateQueries({
        queryKey: [
          "marketMove",
          variables.marketMoveRun.marketMoveId.toString(),
        ],
      });
    },
  });
};

export const useUpdateMarketMoveRun = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (marketMove: MarketMoveRunSchema) =>
      updateMarketMove(marketMove),
    onSuccess: (_, variables) => {
      console.debug(
        "marketMoveRun useUpdateMarketMoveRun onSuccess",
        variables
      );
      queryClient.invalidateQueries({ queryKey: ["marketMoves"] });
      queryClient.invalidateQueries({
        queryKey: [
          "marketMoveRun",
          variables.marketMoveRun.marketMoveRunId.toString(),
        ],
      });
      queryClient.invalidateQueries({
        queryKey: ["marketMove", variables.marketMoveRun.marketMoveId],
      });
    },
  });
};

export const useGetSingleMMRun = (mmRunId: string | null) => {
  const showPastMarketMoves = useGeneralStore(
    (state) => state.showPastMarketMoves
  );
  return useQuery({
    queryKey: ["marketMoveRun", mmRunId?.toString(), showPastMarketMoves],
    queryFn: () => getSingleMMRun(mmRunId, showPastMarketMoves),
    enabled: !!mmRunId,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      console.debug("marketMoveRun useGetSingleMMRun onSuccess", data);
    },
    onError: (error: AxiosError) => {
      console.error("marketMoveRun useGetSingleMMRun onError", error);
      enqueueSnackbar(`Error fetching market move run: ${error.message}`, {
        variant: "error",
      });
    },
    retry: 1,
    staleTime: 10 * 1000, // ten seconds
  });
};

export const prefetchSingleMMRun = async (
  queryClient: QueryClient,
  mmRunId: string,
  showPastMarketMoves: boolean
) => {
  if (mmRunId) {
    await queryClient.prefetchQuery({
      queryKey: ["marketMoveRun", mmRunId.toString(), showPastMarketMoves],
      queryFn: () => getSingleMMRun(mmRunId, showPastMarketMoves),
      retry: 1,
    });
  }
};

export const useInfiniteScrollGetMarketMoveRunsByRunStatus = (
  statuses: string[],
  sortBy: string,
  sortDirection: string,
  searchValue: string
) => {
  return useInfiniteQuery({
    queryKey: [
      "InfiniteMarketMoveRunsByRunStatus",
      statuses,
      sortBy,
      sortDirection,
      searchValue,
    ],
    queryFn: ({ pageParam = 0 }) =>
      getMarketMoveRunsByRunStatus(
        statuses,
        sortBy,
        sortDirection,
        pageParam,
        5,
        searchValue
      ),
    getNextPageParam: (lastPage, pages) => lastPage.nextPage,
  });
};

export const useCheckMarketMoveOverlap = (
  params: OverlapCheckRequest | null
) => {
  return useQuery<MarketMoveOverlapResponse[], AxiosError>({
    queryKey: [
      "marketMoveOverlap",
      params?.startDate,
      params?.endDate,
      params?.storeProducts,
    ],
    queryFn: () => {
      if (!params) return Promise.resolve([]);
      return checkMarketMoveOverlap(params);
    },
    enabled: !!params,
    staleTime: 30 * 1000, // 30 seconds
    onError: (error: AxiosError) => {
      enqueueSnackbar("Failed to check for overlaps", { variant: "error" });
    },
  });
};
