import { GridRenderEditCellParams } from "@mui/x-data-grid";
import useControlStore from "../store/useStoreControl";
import { SENumberInput, SEPriceInput } from "@7eleven/visops-react-ui-kit";
import { useEffect, useState } from "react";

const MaxIncDecEditField: React.FC<GridRenderEditCellParams> = (params) => {
  const [value, setValue] = useState(params?.value);
  const { setMaxIncDecGridErrors, maxIncDecGridErrors } = useControlStore();
  const handleTooltipChange = (show: boolean, message: string) => {};
  useEffect(() => {
    params.api.setEditCellValue(
      {
        id: params.id,
        field: params.field,
        value: value,
      },
      true
    );
  }, [value]);
  const handleMaxIncDecChange = (value: string) => {
    console.log(value);

    const isValid = Number(value) >= 0 && Number(value) <= 1 && !(value == "");
    if (!isValid) {
      setMaxIncDecGridErrors(
        params.id.toString(),
        params.field,
        "Value must be between 0 and 1",
        false
      );
    } else {
      setMaxIncDecGridErrors(
        params.id.toString(),
        params.field,
        undefined,
        false
      );
    }
    setValue(value);
  };
  return (
    <>
      <SENumberInput
        label=""
        value={value ?? ""}
        size="small"
        variant="outlined"
        error={!!maxIncDecGridErrors?.[params.id]?.[params.field]}
        min={0}
        max={1}
        step={0.01}
        precision={2}
        tooltipMessage={maxIncDecGridErrors?.[params.id]?.[params.field]}
        isTooltipControlled={false}
        onTooltipChange={handleTooltipChange}
        handleChange={handleMaxIncDecChange}
        sx={{
          width: "100%",
          height: "100%",
          "& .MuiOutlinedInput-root": {
            height: "100%",
            padding: 0,
          },
          "& .MuiInputBase-input": {
            padding: "0 8px",
            textAlign: "left",
            boxSizing: "border-box",
          },
        }}
      />
    </>
  );
};
export default MaxIncDecEditField;
