import React, { useEffect, useState } from "react";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import useControlStore from "../store/useStoreControl";
import { SEPriceInput } from "@7eleven/visops-react-ui-kit";

const PriceEditField: React.FC<GridRenderEditCellParams> = (params) => {
  const { minMaxGridErrors, setMinMaxGridErrors } = useControlStore();
  const [value, setValue] = useState(params?.value);

  const validatePrice = (field: string, newValue: number, row: any) => {
    if (field === "min_price_new") {
      if (newValue >= row.max_price_new) {
        setMinMaxGridErrors(
          row.id,
          [
            {
              field: "min_price_new",
              message: "Min price must be less than max price.",
            },
            {
              field: "max_price_new",
              message: "Max Price must be greater than Min price",
            },
          ],
          true
        );
      } else {
        setMinMaxGridErrors(row.id, [], false);
      }
    } else if (field === "max_price_new") {
      if (newValue <= row.min_price_new) {
        setMinMaxGridErrors(
          row.id,
          [
            {
              field: "min_price_new",
              message: "Min Price cannot be greater than or equal to Max price",
            },
            {
              field: "max_price_new",
              message: "Max Price cannot be less than or equal to Min price",
            },
          ],
          true
        );
      } else {
        setMinMaxGridErrors(row.id, [], false);
      }
    }
  };

  useEffect(() => {
    params.api.setEditCellValue(
      {
        id: params.id,
        field: params.field,
        value: value,
      },
      true
    );
  }, [value]);
  const handleChange = (value: string) => {
    // Allow empty input
    if (value === "") {
      validatePrice(params.field, 0, params.row);
      setValue(0);
      return;
    }

    // Allow numeric input with exactly three decimal places or up to three decimal places
    const regex = /^\d*\.?\d{0,3}$/;
    const formattedRegex = /^\d*\.\d{3}$/;

    if (regex.test(value) || formattedRegex.test(value)) {
      if (
        params.field === "min_price_new" ||
        params.field === "max_price_new"
      ) {
        const newValue = Number(value);
        validatePrice(params.field, newValue, params.row);
      }
      setValue(value);
    }
  };

  const handleTooltipChange = (show: boolean, message: string) => {};
  return (
    <>
      <SEPriceInput
        label=""
        value={value ?? ""}
        size="small"
        variant="outlined"
        error={!!minMaxGridErrors?.[params?.id]}
        handleChange={handleChange}
        min={0}
        step={0.01}
        tooltipMessage={minMaxGridErrors?.[params.id]?.[params.field]}
        // showTooltip={!!minMaxGridErrors?.[params.id]?.[params.field]}
        isTooltipControlled={false}
        onTooltipChange={handleTooltipChange}
        sx={{
          width: "100%",
          height: "100%",
          "& .MuiOutlinedInput-root": {
            height: "100%",
            padding: 0,
          },
          "& .MuiInputBase-input": {
            padding: "0 8px",
            textAlign: "left",
            boxSizing: "border-box",
          },
        }}
      />
    </>
  );
};

export default PriceEditField;
