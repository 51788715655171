import { Wrapper } from "@googlemaps/react-wrapper";
import PriceComparison from "./price-comparison";
import { StoreProximityProps } from "../../../types/StoreProximityProps";

const ProximityWrapper: React.FC<StoreProximityProps> = (proximityProps) => {
  let wrapper = <></>;
  if (proximityProps && proximityProps.storeData) {
    try {
      wrapper = (
        <>
          <PriceComparison {...proximityProps} />
        </>
      );
    } catch (error) {
      console.error("Error occured in store proximity", error);
      wrapper = <div> Error... </div>;
    }
  } else {
    wrapper = <div> No Data for store proximity </div>;
  }

  return wrapper;
};

export default ProximityWrapper;
