import constants from "../constants/constants.json";

export function getChangedProperties(newRow: any, originalRow: any): string[] {
  const changedProperties: string[] = [];

  // Iterate over the keys of the newRow
  for (const key in newRow) {
    // Check if the key exists in originalRow and the values are different
    if (newRow.hasOwnProperty(key) && newRow[key] != originalRow[key]) {
      changedProperties.push(key);
    }
  }

  return changedProperties;
}

export function getUserName() {
  return sessionStorage.getItem(constants.USER_NAME_KEY) || "UNKNOWN USER";
}

export function mergeIfDifferent(obj1, obj2) {
  const merged = { ...obj1 }; // Start with a copy of obj1

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      const value1 = obj1[key];
      const value2 = obj2[key];

      // Normalize values to the same type (number for this example)
      const normalizedValue1 =
        typeof value1 === "string" ? parseFloat(value1) : value1;
      const normalizedValue2 =
        typeof value2 === "string" ? parseFloat(value2) : value2;

      // Merge only if values are different after normalization
      if (normalizedValue1 !== normalizedValue2) {
        merged[key] = obj2[key];
      }
    }
  }

  return merged;
}
