import { z } from "zod";
import {
  AfterMarketMoveAction,
  MarketMoveTemplateStatus,
  PriceMode,
  RunStatus,
} from "../interfaces/enums";
import { getUserName } from "../utils/misc";
import { formatISODate } from "../utils/textUtils";
import dayjs from "dayjs";

// Enums and Constants
const MarketMoveStatusSchema = z.nativeEnum(MarketMoveTemplateStatus);
const runStatusSchema = z.nativeEnum(RunStatus);
const PriceModeSchema = z.nativeEnum(PriceMode);
const UpdateFlag = z.enum(["INSERT", "UPDATE", "REMOVE", "NOUPDATE"]);

// Basic Schemas
const marketMoveStoreListSchema = z.object({
  marketMoveId: z.number().optional(),
  storeListId: z.number().optional(),
  storeId: z.string(),
  includeDate: z.string(),
  includeFlag: z.boolean(),
  flag: UpdateFlag.optional(),
});

const marketMoveProductConfigSchema = z
  .object({
    marketMoveId: z.number().optional().nullable(),
    marketMoveRunId: z.number().optional().nullable(),
    productConfigId: z.number().optional().nullable(),
    mmRunProdConfigId: z.number().optional().nullable(),
    productId: z.string(),
    priceMode: PriceModeSchema.default(PriceMode.INCREMENTAL),
    baseMinPrice: z
      .number()
      .gte(1, { message: "Base min price must be 1 or greater" })
      .optional()
      .nullable(),
    baseMaxPrice: z
      .number()
      .gte(1, { message: "Base max price must be 1 or greater" })
      .optional()
      .nullable(),
    absPrice: z
      .number()
      .gte(1, { message: "Absolute price must be 1 or greater" })
      .optional()
      .nullable(),
    priceIncrement: z
      .number()
      .gte(-100, { message: "Price increment must be between -100 and 100" })
      .lte(100, { message: "Price increment must be between -100 and 100" })
      .optional()
      .nullable(),
    excludePriceBelow: z.number().optional().nullable(),
    excludePriceAbove: z.number().optional().nullable(),
    applyLinkedProducts: z.boolean(),
  })
  .refine(
    (data) => {
      if (data.priceMode === PriceMode.INCREMENTAL) {
        return (
          data.priceIncrement !== null &&
          data.priceIncrement !== undefined &&
          data.priceIncrement !== 0
        );
      }
      return true;
    },
    {
      message: "Price increment is required for incremental price mode",
      path: ["priceIncrement"],
    }
  );

const marketMoveRunDetailSchema = z.object({
  storeId: z.string(),
  marketMoveId: z.number().optional().nullable(),
  mmRunRecapId: z.number().optional().nullable(),
  productId: z.string(),
  includeFlag: z.boolean(),
  manualPriceOverrideFlag: z.boolean(),
  manualPrice: z.number().nullable(),
  originalPrice: z.number().nullable(),
  newPrice: z.number().nullable(),
  delta: z.number().nullable(),
  excludePriceFlag: z.boolean(),
  exclusionReason: z.string().nullable().default(""),
  mmStatus: z.string(),
  canceledDate: z.date().nullable(),
  cancelledBy: z.string().nullable(),
  cancelledReason: z.string().nullable(),
  mmPriceExport: z.string().nullable(),
});

const storeProductConfigSchema = z.object({
  storeId: z.string(),
  productId: z.string(),
  includeFlag: z.boolean(),
  manualPriceOverrideFlag: z.boolean(),
  manualPrice: z.number(),
});

// Complex Schemas
export const previewDetailsSchema = z.object({
  marketMoveId: z.string(),
  marketMoveRunId: z.string().nullable(),
  marketMoveName: z.string(),
  marketMoveStores: z.array(marketMoveStoreListSchema),
  marketMoveProductConfigs: z.array(marketMoveProductConfigSchema),
  storeProductConfig: z.array(storeProductConfigSchema),
});

const baseMarketMoveMetaDataSchema = z.object({
  marketMoveId: z.string().optional(),
  marketMoveName: z.string().optional(),
  marketMoveDescription: z.string().default(""),
  marketMoveRunId: z.string().optional().nullable(),
  startDate: z
    .string()
    .optional()
    .default(() => formatISODate(new Date())),
  endDate: z
    .string()
    .optional()
    .default(() => formatISODate(new Date(9999, 11, 31, 23, 59, 59))),
  createdBy: z.string().optional().default(getUserName()),
  createdDate: z.string().optional().default(formatISODate()),
  modifiedBy: z.string().optional().default(getUserName()),
  modifiedDate: z.string().optional().default(formatISODate()),
  saved: z.boolean().optional(),
  templateStatus: MarketMoveStatusSchema.default(
    MarketMoveTemplateStatus.INACTIVE
  ),
  runStatus: runStatusSchema.nullable().default(RunStatus.DRAFT),
  storeList: z.array(marketMoveStoreListSchema).optional(),
  storeCount: z.number().optional().readonly(),
  productConfigList: z.array(marketMoveProductConfigSchema).optional(),
  postMMAction: z
    .nativeEnum(AfterMarketMoveAction)
    .default(AfterMarketMoveAction.RETURNTOPREVAILINGSTRATEGY),
});

const isDevOrTestOrLocal =
  window.location.href.includes("test") ||
  window.location.href.includes("dev") ||
  window.location.hostname === "localhost";
const minTimeDiff = isDevOrTestOrLocal ? 15 : 60;

export const marketMoveMetaDataSchema = baseMarketMoveMetaDataSchema
  .refine(
    (data) => {
      if (data.runStatus === RunStatus.INPROGRESS) {
        return true;
      }
      if (data.startDate && data.endDate) {
        const start = dayjs(data.startDate);
        const end = dayjs(data.endDate);
        // const now = dayjs()
        return start.isBefore(end);
      }
      return true;
    },
    {
      message: "Start date must be in the future and before the end date",
      path: ["startDate"],
    }
  )
  .refine(
    (data) => {
      if (data.startDate && data.endDate) {
        const start = new Date(data.startDate);
        const end = new Date(data.endDate);
        const diffInMinutes = (end.getTime() - start.getTime()) / (1000 * 60);

        return end > start && diffInMinutes >= minTimeDiff;
      }
      return true;
    },
    {
      message: `End date must be after start date and at least ${minTimeDiff} minutes apart`,
      path: ["endDate"],
    }
  )
  .refine((data) => data.marketMoveName.trim().length > 0, {
    message: "Market move name cannot be empty",
    path: ["marketMoveName"],
  });

const additionalFields = z.object({
  recapDate: z.string().default(formatISODate()),
  recapCreatedBy: z.string().default(getUserName()),
  recapModifiedBy: z.string().default(getUserName()),
  cancelStatus: z.string().nullable(),
  canceledDate: z.string().nullable(),
  canceledBy: z.string().nullable(),
  cancellationReason: z.string().nullable(),
});

export const marketMoveRunSchema = z.object({
  marketMoveRun: z.object({
    ...baseMarketMoveMetaDataSchema.shape,
    ...additionalFields.shape,
  }),
  marketMoveRunProductConfigs: z.array(marketMoveProductConfigSchema),
  marketMoveRunRecapDetails: z.array(marketMoveRunDetailSchema),
  marketMoveRunDetails: z.array(marketMoveRunDetailSchema).default([]),
});

const runsListSchemaField = z.object({
  marketMoveRuns: z.array(marketMoveRunSchema),
});

const MarketMoveMetaDataWithJoinedRunsSchema =
  marketMoveMetaDataSchema.and(runsListSchemaField);

const marketMovePagedRunsSchema = z.object({
  data: z.array(MarketMoveMetaDataWithJoinedRunsSchema),
  nextPage: z.number().optional(),
  totalPages: z.number(),
});

// Type Exports
export type MarketMoveMetaDataSchema = z.infer<typeof marketMoveMetaDataSchema>;
export type MarketMoveStoreListSchema = z.infer<
  typeof marketMoveStoreListSchema
>;
export type MarketMoveProductConfigSchema = z.infer<
  typeof marketMoveProductConfigSchema
>;
export type MarketMoveRunDetailSchema = z.infer<
  typeof marketMoveRunDetailSchema
>;
export type StoreProductConfigSchema = z.infer<typeof storeProductConfigSchema>;
export type UpdateFlag = z.infer<typeof UpdateFlag>;
export type RunStatusSchema = z.infer<typeof runStatusSchema>;
export type PreviewDetailsSchema = z.infer<typeof previewDetailsSchema>;
export type MarketMoveRunSchema = z.infer<typeof marketMoveRunSchema>;
export type MarketMoveMetaDataWithJoinedRunsSchema = z.infer<
  typeof MarketMoveMetaDataWithJoinedRunsSchema
>;

export type MarketMovePagedRunsSchema = z.infer<
  typeof marketMovePagedRunsSchema
>;
 