import constants from "../constants/constants.json";
import { MarketMoveRunSchema, RunStatusSchema } from "../schema/schemas";
import { formServiceUrl } from "../utils/apiUrlUtil";
import { createApiClient } from "./apiClient";
import { MarketMovePagedRunsSchema } from "../schema/pastExecutionSchemas";
import { RunStatus } from "../interfaces/enums";

const {
  urlConstants: { compositeMaster },
} = constants;
const {
  name: SERVICE_NAME,
  getRecapDetails,
  updateRecapDetails,
  saveRecapDetails,
  getRunsByRunStatus,
  checkOverlap,
} = compositeMaster;

const baseUrlFactory = async () => formServiceUrl(SERVICE_NAME);

const apiClient = createApiClient(baseUrlFactory);

export interface MarketMoveRunStatus {
  marketMoveId: string;
  marketMoveRunId: string;
  runStatus: RunStatusSchema;
}

export const getSingleMMRun = async (
  mmId: string,
  showPastMarketMoves?: boolean
): Promise<MarketMoveRunSchema> => {
  const response = await apiClient.get<MarketMoveRunSchema>(
    `${getRecapDetails}?marketMoveRunId=${mmId}`
  );
  return response;
};

export const createMarketMove = (marketMove: MarketMoveRunSchema) => {
  return apiClient.post<MarketMoveRunSchema>(saveRecapDetails, marketMove);
};

export const updateMarketMove = (marketMove: MarketMoveRunSchema) => {
  return apiClient.put<MarketMoveRunSchema>(updateRecapDetails, marketMove);
};

export const getMarketMoveRunsByRunStatus = (
  statuses: string[],
  sortBy: string,
  sortDirection: string,
  pageNo: number,
  pageSize: number,
  searchValue: string
) => {
  const request = {
    statuses: statuses,
    sortBy: sortBy,
    sortDirection: sortDirection,
    pageNo: pageNo,
    pageSize: pageSize,
    searchValue: searchValue,
  };

  return apiClient.post<MarketMovePagedRunsSchema>(getRunsByRunStatus, request);
};

export interface StoreProduct {
  storeId: string;
  productId: string;
}

export interface OverlapCheckRequest {
  startDate: string;
  endDate: string;
  storeProducts: StoreProduct[];
}

export interface MarketMoveOverlapResponse {
  storeId: string;
  productId: string;
  overlaps: Array<{
    marketMoveId: number;
    marketMoveRunId: number;
    marketMoveName: string;
    startDate: string;
    endDate: string;
    runStatus: RunStatusSchema;
  }>;
}

export const checkMarketMoveOverlap = (params: OverlapCheckRequest) => {
  return apiClient.post<MarketMoveOverlapResponse[]>(checkOverlap, params);
};
