import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchCompetitorPrices,
  CompetitorPriceData,
} from "../api/competitorPricesApi";

interface UseCompetitorPricesParams {
  seiId: string;
  priceGenIdList: string[];
  coords?: {
    lat?: number | string;
    lon?: number | string;
    latitude?: number | string;
    longitude?: number | string;
  };
  enabled?: boolean;
}

const getNormalizedCoords = (coords: UseCompetitorPricesParams["coords"]) => {
  if (!coords) return null;

  const lat = coords.lat || coords.latitude;
  const lon = coords.lon || coords.longitude;

  if (!lat || !lon) return null;

  return {
    lat: typeof lat === "string" ? parseFloat(lat) : lat,
    lon: typeof lon === "string" ? parseFloat(lon) : lon,
  };
};

export const useCompetitorPrices = ({
  seiId,
  priceGenIdList,
  coords,
  enabled = true,
}: UseCompetitorPricesParams) => {
  const normalizedCoords = getNormalizedCoords(coords);
  const queryClient = useQueryClient();

  const query = useQuery<CompetitorPriceData[]>({
    queryKey: ["competitorPrices", seiId, priceGenIdList, normalizedCoords],
    queryFn: () => {
      if (!normalizedCoords) {
        throw new Error("Missing or invalid coordinates");
      }
      return fetchCompetitorPrices({
        seiId,
        priceGenIdList,
        location: normalizedCoords,
      });
    },
    enabled: enabled && Boolean(normalizedCoords) && priceGenIdList.length > 0,
  });

  const refetchCompetitorPrices = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["competitorPrices", seiId],
    });
  };

  return { ...query, refetchCompetitorPrices };
};
