import React, { createContext, useState, useContext, useMemo } from "react";
import { PriceDataContext } from "./PriceDataContext";
import { CompetitorPriceData } from "../api/competitorPricesApi";
import { useCompetitorPrices } from "../hooks/useCompetitorPrices";

interface CompetitorPriceContextType {
  initialData: CompetitorPriceData[];
  setInitialData: React.Dispatch<React.SetStateAction<CompetitorPriceData[]>>;
  competitorPriceData: CompetitorPriceData[];
  setCompetitorPriceData: React.Dispatch<
    React.SetStateAction<CompetitorPriceData[]>
  >;
  gridState: "adding" | "removing";
  setGridState: React.Dispatch<React.SetStateAction<"adding" | "removing">>;
  distance: string;
  setDistance: React.Dispatch<React.SetStateAction<string>>;
  size: number;
  setSize: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  error: unknown;
  refetchCompetitorPrices: () => Promise<void>;
}

export const CompetitorPriceDataContext =
  createContext<CompetitorPriceContextType>({
    initialData: [],
    setInitialData: () => {},
    competitorPriceData: [],
    setCompetitorPriceData: () => {},
    gridState: "removing",
    setGridState: () => {},
    distance: "5mi",
    setDistance: () => {},
    size: 25,
    setSize: () => {},
    isLoading: false,
    error: null,
    refetchCompetitorPrices: async () => {},
  });

export const CompetitorPriceDataProvider: React.FC<{
  children: React.ReactNode;
  coords?: {
    lat?: number | string;
    lon?: number | string;
    latitude?: number | string;
    longitude?: number | string;
  };
}> = ({ children, coords }) => {
  const [initialData, setInitialData] = useState<CompetitorPriceData[]>([]);
  const [competitorPriceData, setCompetitorPriceData] = useState<
    CompetitorPriceData[]
  >([]);
  const [gridState, setGridState] = useState<"adding" | "removing">("removing");
  const [distance, setDistance] = useState<string>("5mi");
  const [size, setSize] = useState<number>(25);

  const { priceData, seiId } = useContext(PriceDataContext);

  const { data, isLoading, error, refetchCompetitorPrices } =
    useCompetitorPrices({
      seiId,
      priceGenIdList: priceData?.map((a) => a.priceData.latestPriceGenId) ?? [],
      coords,
      enabled: Boolean(priceData?.length && coords),
    });

  // Update initialData when query data changes
  React.useEffect(() => {
    if (data) {
      setInitialData(data);
    }
  }, [data]);

  const value = useMemo(
    () => ({
      initialData,
      setInitialData,
      competitorPriceData,
      setCompetitorPriceData,
      gridState,
      setGridState,
      distance,
      setDistance,
      size,
      setSize,
      isLoading,
      error,
      refetchCompetitorPrices,
    }),
    [
      initialData,
      competitorPriceData,
      gridState,
      distance,
      size,
      isLoading,
      error,
      refetchCompetitorPrices,
    ]
  );

  return (
    <CompetitorPriceDataContext.Provider value={value}>
      {children}
    </CompetitorPriceDataContext.Provider>
  );
};
