import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import useDialogStore from "../../store/useDialogStore";

const GlobalDialog = () => {
  const {
    isOpen,
    content,
    actions,
    closeDialog,
    acceptCallback,
    discardCallback,
    acceptButtonText = "Accept",
    discardButtonText = "Discard",
    isSingleButton,
  } = useDialogStore();

  const renderButtons = () => {
    if (actions) return actions;

    if (isSingleButton) {
      return (
        <Button onClick={acceptCallback} color="primary" sx={{ minWidth: 100 }}>
          {acceptButtonText}
        </Button>
      );
    }

    return (
      <>
        <Button
          onClick={acceptCallback}
          color="primary"
          sx={{ color: "green" }}
        >
          {acceptButtonText}
        </Button>
        <Button
          onClick={discardCallback}
          color="secondary"
          sx={{ color: "red" }}
        >
          {discardButtonText}
        </Button>
      </>
    );
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogContent>{content}</DialogContent>
      <DialogActions
        sx={{ justifyContent: isSingleButton ? "center" : "flex-end", p: 2 }}
      >
        {renderButtons()}
      </DialogActions>
    </Dialog>
  );
};

export default GlobalDialog;
