import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import PriceDataModel from "../models/priceView/PriceData.model";

import { useFetchAndUpdateProposedPrices } from "../hooks/priceViewApis/useFetchAndUpdateProposedPrices";
import { useProductMapping } from "../hooks/useMongoWebHooks";
import { StatusType } from "../types/PriceReview.types";
import { PriceDataContextType } from "../types/priceDataContext.types";
import PriceDataUtilities from "../util/PriceDataUtilities";
import { useGetProductStrategies } from "../api/getStrategyApi";

export const PriceDataContext = createContext<PriceDataContextType | null>(
  null
);

interface PriceDataProviderProps {
  children: ReactNode;
  seiId?: string;
  fpisStoreId?: string;
}

const changeProposedPriceStatus =
  (setPriceData, moveLinkedProducts) =>
  (newStatus: StatusType, productId: string) => {
    setPriceData((prevPriceData) => {
      const { isParent, parentItem } = findParentInfo(prevPriceData, productId);
      const isUndoingParent = isParent && parentItem?.status === newStatus;

      return prevPriceData.map((dataItem) => {
        const isClickedProduct = dataItem.productId === productId;
        const isLinkedProduct =
          moveLinkedProducts &&
          isParent &&
          dataItem.parentProductId === productId;

        if (!isClickedProduct && !isLinkedProduct) {
          return dataItem;
        }

        const ret = dataItem.clone();

        if (isClickedProduct) {
          if (
            newStatus === "APPROVED" &&
            dataItem.status === "APPROVED" &&
            dataItem.isManualPriceSet
          ) {
            ret.proposedPrice = dataItem.priceData.latestPriceGenPrice;
            ret.status = null;
          } else {
            ret.status = dataItem.status === newStatus ? null : newStatus;
          }
        } else if (isLinkedProduct && !isUndoingParent) {
          ret.status = newStatus;
        }

        return ret;
      });
    });
  };

const isTargetProduct = (dataItem: PriceDataModel, productId: string) =>
  dataItem.productId === productId;

const isLinkedToParent = (
  dataItem: PriceDataModel,
  productId: string,
  isParent: boolean,
  moveLinkedProducts: boolean
) => moveLinkedProducts && isParent && dataItem.parentProductId === productId;

const findParentInfo = (priceData: PriceDataModel[], productId: string) => {
  let isParent = false;
  let parentItem = null;

  for (const item of priceData) {
    if (item.parentProductId === productId) isParent = true;
    if (item.productId === productId) parentItem = item;
    if (isParent && parentItem) break;
  }

  return { isParent, parentItem };
};

const shouldUpdatePrice = (
  dataItem: PriceDataModel,
  productId: string,
  isParent: boolean,
  moveLinkedProducts: boolean
) =>
  isTargetProduct(dataItem, productId) ||
  isLinkedToParent(dataItem, productId, isParent, moveLinkedProducts);

const adjustProposedPrice =
  (setPriceData, moveLinkedProducts) =>
  (increment: boolean, productId: string) => {
    if (!moveLinkedProducts) {
      PriceDataUtilities.adjustProposedPrice(
        setPriceData,
        increment,
        productId
      );
      return;
    }

    setPriceData((prevPriceData) => {
      const { isParent, parentItem } = findParentInfo(prevPriceData, productId);
      const adjustmentAmount = increment ? 0.01 : -0.01;

      return prevPriceData.map((dataItem) => {
        if (
          !shouldUpdatePrice(dataItem, productId, isParent, moveLinkedProducts)
        )
          return dataItem;

        const ret = dataItem.clone();
        ret.proposedPrice = dataItem.proposedPrice + adjustmentAmount;
        ret.status =
          ret.proposedPrice === dataItem.priceData.latestPriceGenPrice
            ? null
            : "APPROVED";
        return ret;
      });
    });
  };

const changeProposedPriceStatusForAll =
  (setPriceData) => (newStatus: StatusType) =>
    PriceDataUtilities.changeProposedPriceStatusForAll(setPriceData, newStatus);

const adjustProposedPriceForAll =
  (setPriceData, moveLinkedProducts) => (increment: boolean) => {
    if (!moveLinkedProducts) {
      PriceDataUtilities.adjustProposedPriceForAll(setPriceData, increment);
      return;
    }

    setPriceData((prevPriceData) => {
      const adjustmentAmount = increment ? 0.01 : -0.01;

      return prevPriceData.map((dataItem) => {
        const ret = dataItem.clone();
        ret.proposedPrice = dataItem.proposedPrice + adjustmentAmount;
        ret.status = "APPROVED";
        return ret;
      });
    });
  };

const areAnyPricesFinalized = (priceData) => () =>
  PriceDataUtilities.areAnyPricesFinalized(priceData);

const updateProposedPrice =
  (setPriceData, moveLinkedProducts) =>
  (newPrice: number, productId: string) => {
    setPriceData((prevPriceData) => {
      const { isParent, parentItem } = findParentInfo(prevPriceData, productId);
      const oldPrice = parentItem?.proposedPrice;

      if (parentItem && newPrice === parentItem.proposedPrice) {
        return prevPriceData;
      }

      return prevPriceData.map((dataItem) => {
        if (!shouldUpdatePrice(dataItem, productId, isParent, moveLinkedProducts)) {
          return dataItem;
        }

        const ret = dataItem.clone();
        if (isTargetProduct(dataItem, productId)) {
          ret.setProposedPrice(newPrice);
          ret.status = "APPROVED";
        } else {
          const priceDifference = newPrice - oldPrice;
          ret.setProposedPrice(dataItem.proposedPrice + priceDifference);
          ret.status = "APPROVED";
        }
        return ret;
      });
    });
  };

const setProposedPricesStatus =
  (setPriceData) => (proposedPrices: IProposedPrice[]) =>
    PriceDataUtilities.setProposedPriceStatusFromProposedPrices(
      setPriceData,
      proposedPrices
    );

export const PriceDataProvider: React.FC<PriceDataProviderProps> = ({
  children,
  seiId,
  fpisStoreId,
}) => {
  const [priceData, setPriceData] = useState<PriceDataModel[]>([]);
  const [selectedPriceDataIndex, setSelectedPriceDataIndex] = useState(0);
  const [selectedPriceData, setSelectedPriceData] =
    useState<PriceDataModel>(null);
  const [moveLinkedProducts, setMoveLinkedProducts] = useState(true);
  const exportCheckIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const exportCheckCleanupRef = useRef<(() => void) | null>(null);

  const { data: strategiesData } = useGetProductStrategies(seiId);
  const { data, refetch: refreshProposedPrices } =
    useFetchAndUpdateProposedPrices(seiId);

  useProductMapping();

  const scheduleProposedPricesExportCheck = useCallback(() => {
    // Clean up any existing intervals and cleanup functions
    if (exportCheckIntervalRef.current) {
      clearInterval(exportCheckIntervalRef.current);
      exportCheckIntervalRef.current = null;
    }
    if (exportCheckCleanupRef.current) {
      exportCheckCleanupRef.current();
      exportCheckCleanupRef.current = null;
    }

    let attempts = 0;
    const maxAttempts = 60; // 5 minutes with 5-second intervals

    const checkInterval = setInterval(async () => {
      attempts++;

      const result = await refreshProposedPrices();
      if (!result.data) return;

      let statusChanged = false;
      const newPriceData = priceData.map((pd) => {
        const newPrice = result.data.find((d) => d.productId === pd.productId);
        if (
          newPrice &&
          newPrice.priceData.latestPriceGenPriceStatus === "EXPORTED" &&
          pd.priceData.latestPriceGenPriceStatus !== "EXPORTED"
        ) {
          statusChanged = true;
          return newPrice;
        }
        return pd;
      });

      if (statusChanged) {
        setPriceData(newPriceData);
      }

      const allExported = result.data.every(
        (price) => price.priceData.latestPriceGenPriceStatus === "EXPORTED"
      );

      if (allExported || attempts >= maxAttempts) {
        clearInterval(checkInterval);
        exportCheckIntervalRef.current = null;
      }
    }, 5000);

    exportCheckIntervalRef.current = checkInterval;

    // Store cleanup function
    exportCheckCleanupRef.current = () => {
      if (exportCheckIntervalRef.current) {
        clearInterval(exportCheckIntervalRef.current);
        exportCheckIntervalRef.current = null;
      }
    };

    return exportCheckCleanupRef.current;
  }, [priceData, refreshProposedPrices]);

  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      if (exportCheckCleanupRef.current) {
        exportCheckCleanupRef.current();
      }
    };
  }, []);

  useEffect(() => {
    const enrichedPriceData = data?.map((priceDataItem) => {
      const strategy = strategiesData?.find(
        (s) => s.mapping.productId === priceDataItem.productId
      );

      if (!strategy) return priceDataItem;

      // Find parent product ID if this is a linked product
      const parentRule = strategy.rules.find(
        (rule) => rule.type === "RuleLinkToOwnProduct" && rule.linkedProduct
      );

      const newPriceData = priceDataItem.clone();
      newPriceData.parentProductId = parentRule?.linkedProduct || null;
      newPriceData.spread = parentRule?.spread || null;

      return newPriceData;
    });
    setPriceData(enrichedPriceData || []);
  }, [data, strategiesData]);

  useEffect(() => {
    if (priceData.length === 0) {
      return;
    }

    let newSelectedIndex = selectedPriceDataIndex;

    if (newSelectedIndex == null) {
      newSelectedIndex = 0;
    }
    if (selectedPriceDataIndex > priceData.length - 1) {
      newSelectedIndex = priceData.length - 1;
    }

    setSelectedPriceData(priceData[newSelectedIndex]);
    setSelectedPriceDataIndex(newSelectedIndex);
  }, [priceData]);

  const changeProposedPriceStatusMemo = useCallback(
    changeProposedPriceStatus(setPriceData, moveLinkedProducts),
    [setPriceData, moveLinkedProducts]
  );
  const adjustProposedPriceMemo = useCallback(
    adjustProposedPrice(setPriceData, moveLinkedProducts),
    [setPriceData, moveLinkedProducts]
  );
  const changeProposedPriceStatusForAllMemo = useCallback(
    changeProposedPriceStatusForAll(setPriceData),
    [setPriceData]
  );
  const adjustProposedPriceForAllMemo = useCallback(
    adjustProposedPriceForAll(setPriceData, moveLinkedProducts),
    [setPriceData, moveLinkedProducts]
  );
  const areAllPricesFinalizedMemo = useCallback(
    areAnyPricesFinalized(priceData),
    [priceData]
  );
  const updateProposedPriceMemo = useCallback(
    updateProposedPrice(setPriceData, moveLinkedProducts),
    [setPriceData, moveLinkedProducts]
  );

  const setProposedPricesStatusMemo = useCallback(
    setProposedPricesStatus(setPriceData),
    [setPriceData]
  );

  const contextValue = useMemo(
    () => ({
      priceData,
      seiId,
      fpisStoreId,
      setPriceData,
      selectedPriceData,
      selectedPriceDataIndex,
      setSelectedPriceData,
      setSelectedPriceDataIndex,
      scheduleProposedPricesExportCheck:
        scheduleProposedPricesExportCheck as () => (() => void) | undefined,
      fetchGeneratedPrices: refreshProposedPrices,
      changeProposedPriceStatus: changeProposedPriceStatusMemo,
      moveLinkedProducts,
      setMoveLinkedProducts,
      adjustProposedPrice: adjustProposedPriceMemo,
      changeProposedPriceStatusForAll: changeProposedPriceStatusForAllMemo,
      adjustProposedPriceForAll: adjustProposedPriceForAllMemo,
      areAnyPricesFinalized: areAllPricesFinalizedMemo,
      updateProposedPrice: updateProposedPriceMemo,
      setProposedPricesStatus: setProposedPricesStatusMemo,
    }),
    [
      priceData,
      selectedPriceData,
      selectedPriceDataIndex,
      moveLinkedProducts,
      changeProposedPriceStatusMemo,
      adjustProposedPriceMemo,
      changeProposedPriceStatusForAllMemo,
      adjustProposedPriceForAllMemo,
      areAllPricesFinalizedMemo,
      updateProposedPriceMemo,
      setProposedPricesStatusMemo,
      seiId,
      fpisStoreId,
      scheduleProposedPricesExportCheck,
      refreshProposedPrices,
    ]
  );

  return (
    <PriceDataContext.Provider value={contextValue}>
      {children}
    </PriceDataContext.Provider>
  );
};
