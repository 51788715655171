import { GridPreProcessEditCellProps } from "@mui/x-data-grid";
import { TabInfo } from "../interfaces/ITabs";
import { Box, TextField, Tooltip } from "@mui/material";
import PriceEditField from "../components/PriceEditField";
import useControlStore from "../store/useStoreControl";
import MaxIncDecEditField from "../components/MaxIncDecEditField";
export const validationTabs: TabInfo[] = [
  {
    name: "Min/Max Price",
    id: "minMaxPrice",
  },
  {
    name: "Max Inc/Dec",
    id: "maxIncDec",
  },
  {
    name: "Auto Approval",
    id: "autoApproval",
  },
];

const minMaxTabTableCols = [
  {
    field: "id",
    headerName: "SEI ID",
    flex: 1,
    minWidth: 50,
  },
  {
    field: "min_price_old",
    headerName: "Min Price OLD",
    type: "number",
    flex: 1,
    editable: false,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "min_price_new",
    headerName: "Min Price NEW",
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <PriceEditField {...params} />,
  },
  {
    field: "max_price_old",
    headerName: "Max Price OLD",
    type: "number",
    flex: 1,
    editable: false,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "max_price_new",
    headerName: "Max Price NEW",
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <PriceEditField {...params} />,
  },
  {
    field: "enabled_old",
    headerName: "Enabled OLD",
    flex: 1,
    editable: false,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_new",
    headerName: "Enabled NEW",
    type: "singleSelect",
    valueOptions: ["Enabled", "Disabled"],
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "action_on_failure_old",
    headerName: "Failure Action OLD",
    flex: 1,
    minWidth: 90,
    editable: false,
    align: "left",
    headerAlign: "left",
    valueFormatter: (params) => {
      return minMaxPriceActionOnFailureMappings?.[params] || params;
    },
  },
  {
    field: "action_on_failure_new",
    headerName: "Failure Action NEW",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    align: "left",
    headerAlign: "left",
    valueOptions: [
      { value: "LimitPrice", label: "Limit Price" },
      { value: "RequireApproval", label: "Require Manual Approval" },
      { value: "BlockPriceGenCycle", label: "Block Price Export" },
    ],

    // valueOptions: ["MaxLimit", "RequireApproval", "BlockPriceGenCycle"],
    editable: true,
    getOptionValue: (option) => option.value,
    getOptionLabel: (option) => option.label,
    valueFormatter: (params) => {
      return minMaxPriceActionOnFailureMappings?.[params] || params;
    },
  },
];

const maxIncDecTabTableCols = [
  {
    field: "id",
    headerName: "SEI ID",
    flex: 1,
    minWidth: 50,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "max_decrease_old",
    headerName: "Max Decrease OLD",
    type: "number",
    flex: 1,
    editable: false,
    minWidth: 100,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "max_decrease_new",
    headerName: "Max Decrease NEW",
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <MaxIncDecEditField {...params} />,
  },
  {
    field: "max_increase_old",
    headerName: "Max Increase OLD",
    type: "number",
    editable: false,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "max_increase_new",
    headerName: "Max Increase NEW",
    editable: true,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <MaxIncDecEditField {...params} />,
  },
  {
    field: "enabled_old",
    headerName: "Enabled OLD",
    editable: false,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_new",
    headerName: "Enabled NEW",
    editable: true,
    type: "singleSelect",
    valueOptions: ["Enabled", "Disabled"],
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "action_on_failure_old",
    headerName: "Failure Action OLD",
    flex: 1,
    minWidth: 90,
    editable: false,
    align: "left",
    headerAlign: "left",
    valueFormatter: (params) => {
      return maxIncDecActionOnFailureMappings?.[params] || params;
    },
  },
  {
    field: "action_on_failure_new",
    headerName: "Failure Action NEW",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    align: "left",
    headerAlign: "left",
    valueOptions: [
      { value: "MaxLimit", label: "Limit Price" },
      { value: "RequireApproval", label: "Require Manual Approval" },
      { value: "BlockPriceGenCycle", label: "Block Price Export" },
    ],

    // valueOptions: ["MaxLimit", "RequireApproval", "BlockPriceGenCycle"],
    editable: true,
    getOptionValue: (option) => option.value,
    getOptionLabel: (option) => option.label,
    valueFormatter: (params) => {
      return maxIncDecActionOnFailureMappings?.[params] || params;
    },
  },
];

const autoApprovalTableCols = [
  {
    field: "id",
    headerName: "SEI ID",
    flex: 1,
    minWidth: 50,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_old",
    headerName: "Auto Approval Flag OLD",
    editable: false,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_new",
    headerName: "Auto Approval Flag NEW",
    editable: true,
    type: "singleSelect",
    valueOptions: ["Enabled", "Disabled"],
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
];

export const controlTabTableCols = {
  minMaxPrice: minMaxTabTableCols,
  maxIncDec: maxIncDecTabTableCols,
  autoApproval: autoApprovalTableCols,
};

export const globalInputConfigs = {
  minMaxPrice: {
    min_price_new: {
      label: "Min Price",
      type: "number",
      min: 0,
      step: 0.01,
    },
    max_price_new: {
      label: "Max Price",
      type: "number",
      min: 0,
      step: 0.01,
    },
    enabled_new: {
      label: "Enable Flag",
      type: "singleSelect",

      options: [
        { value: "Enabled", label: "Enabled" },
        { value: "Disabled", label: "Disabled" },
      ],
      default_val: "Select Enabled Flag",
    },
    action_on_failure_new: {
      label: "Failure Actions",
      type: "singleSelect",
      options: [
        { value: "LimitPrice", label: "Limit Price" },
        { value: "RequireApproval", label: "Require Manual Approval" },
        { value: "BlockPriceGenCycle", label: "Block Price Export" },
      ],
      default_val: "Select Failure Action",
    },
  },
  maxIncDec: {
    max_decrease_new: {
      label: "Max Decrease",
      type: "range",
      min: 0.0,
      max: 1.0,
    },
    max_increase_new: {
      label: "Max Increase",
      type: "range",
      min: 0.0,
      max: 1.0,
    },
    enabled_new: {
      label: "Enable Flag",
      type: "singleSelect",
      options: [
        { value: "Enabled", label: "Enabled" },
        { value: "Disabled", label: "Disabled" },
      ],
      default_val: "Select Enabled Flag",
    },
    action_on_failure_new: {
      label: "Failure Actions",
      type: "singleSelect",
      options: [
        { value: "MaxLimit", label: "Limit Price" },
        { value: "RequireApproval", label: "Require Manual Approval" },
        { value: "BlockPriceGenCycle", label: "Block Price Export" },
      ],
      default_val: "Select Failure Action",
    },
  },
  autoApproval: {
    enabled_new: {
      label: "Auto Approval Flag",
      type: "singleSelect",
      options: [
        { value: "Enabled", label: "Enabled" },
        { value: "Disabled", label: "Disabled" },
      ],
      default_val: "Select Auto Approval Flag",
    },
  },
};

export const storeTablecols = [
  { field: "seiid", headerName: "SEI ID", width: 50, flex: 1 },
  {
    field: "displayname",
    headerName: "Name",
    flex: 1,
    width: 100,
  },
  {
    field: "dma",
    headerName: "Dma Name",
    flex: 1,
    width: 150,
  },
  {
    field: "brandname",
    headerName: "Brand Name",
    flex: 1,
    width: 110,
  },
];

export const sheetHeaderMapping = {
  storeId: "Store",
  productId: "Product",
  onFailAction: {
    before: "On Fail Action Before",
    after: "On Fail Action After",
  },
  enabled: {
    before: "Enabled Before",
    after: "Enabled After",
  },
  minPrice: {
    before: "Min Price Before",
    after: "Min Price After",
  },
  maxPrice: {
    before: "Max Price Before",
    after: "Max Price After",
  },
  maxIncrease: {
    before: "Max Increase Before",
    after: "Max Increase After",
  },
  maxDecrease: {
    before: "Max Decrease Before",
    after: "Max Decrease After",
  },
  autoApprove: {
    before: "Auto Approve Flag Before",
    after: "Auto Approve Flag After",
  },
};

export const controlNameMapping = {
  PriceFallUnderMinMax: "Min/Max Price",
  MaxIncreaseDecrease: "Max Inc/Dec",
  AutoApproval: "Auto Approval Flag",
};

const maxIncDecActionOnFailureMappings = {
  MaxLimit: "Limit Price",
  RequireApproval: "Require Manual Approval",
  BlockPriceGenCycle: "Block Price Export",
};

const minMaxPriceActionOnFailureMappings = {
  LimitPrice: "Limit Price",
  RequireApproval: "Require Manual Approval",
  BlockPriceGenCycle: "Block Price Export",
};
