import { useQuery } from "@tanstack/react-query";
import { getProposedPrices } from "../../api/proposedPrices";
import PriceDataModel from "../../models/priceView/PriceData.model";

export const useFetchAndUpdateProposedPrices = (seiId: string) => {
  return useQuery<PriceDataModel[]>({
    queryKey: ['proposedPrices', seiId],
    queryFn: () => getProposedPrices(seiId),
    enabled: !!seiId,
    refetchOnWindowFocus: false,
  });
};
