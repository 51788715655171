import apiClient from "../util/apiClient";
import { formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";

export interface ProductPrice {
  productId: string;
  productName: string;
  price: number;
  effectiveTime: string;
  trigger?: any;
}

export interface CompetitorPriceData {
  opisId: string;
  fpisStoreId: string;
  importCode: string;
  displayName: string;
  brandName: string;
  lat: number;
  lon: number;
  distance?: any;
  productPrice: ProductPrice[];
}

interface FetchCompetitorPricesParams {
  seiId: string;
  priceGenIdList: string[];
  location: {
    lat: number;
    lon: number;
  };
}

export const fetchCompetitorPrices = async (
  params: FetchCompetitorPricesParams
): Promise<CompetitorPriceData[]> => {
  const serviceUrl = await formServiceUrl(
    constants.urlConstants.priceGenPriceAdminMaster.name,
    constants.urlConstants.priceGenPriceAdminMaster.getCompetitorPriceList
  );

  const { data } = await apiClient.post<CompetitorPriceData[]>(
    serviceUrl,
    params
  );
  return data;
};
