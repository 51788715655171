import { SEPriceInput } from "@7eleven/visops-react-ui-kit";

const GlobalMinMaxPrice = ({
  disabled,
  field,
  label,
  value,
  onChange,
  min,
  step,
  error,
  helperText,
}) => {
  const handlepriceChange = (newValue) => {
    if (newValue == value) {
      return;
    }
    onChange(field, newValue);
  };
  return (
    <>
      <SEPriceInput
        label={label}
        value={value ?? ""}
        size="medium"
        variant="outlined"
        handleChange={handlepriceChange}
        min={min}
        step={step}
        disabled={disabled}
        helperText={helperText}
        error={error}
      />
    </>
  );
};

export default GlobalMinMaxPrice;
