import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import PricegenContainer from "./components/PriceGenContainer";
import { USER_NAME_KEY } from "./util/constants/stringConstants";

const subscribeMock = (callback) => {
  // get storeId and fpisId based on last url path
  // const storeId = window.location.pathname.split("/").pop();
  // const fallbackStoreId = "21445";
  const mockCustomPropsData = {
    rowSelectionType: "Single",
    storeId: "21445",
    fpisId: "21445",
    description: "21445 - 8402 HARRY HINES BLVD",
    coords: {
      latitude: "32.836589",
      longitude: "-96.863643",
    },
    priceSource: "PriceGen",
  };

  callback(mockCustomPropsData);

  return {
    unsubscribe: () => {},
  };
};

// const customPropsMock = {
//   compRef: {
//     mapLayerService: {
//       openPriceGenWindow$: {
//         subscribe: subscribeMock,
//       },
//     },
//   },
// };

const isLocalhost = window.location.hostname.includes("localhost");

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  // @ts-ignore
  rootComponent: isLocalhost
    ? (props) => <PricegenContainer customProps={props} {...props} />
    : PricegenContainer,
  errorBoundary(_, _1, _2) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});
export const { bootstrap, mount, unmount } = lifecycles;
