import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { MarketMoveOverlapResponse } from "../../../api/marketMoveRunApi";
import { RunStatus } from "../../../interfaces/enums";
import { getUniqueProducts } from "../../../store/actions";
import { formatISODate } from "../../../utils/textUtils";

interface OverlapWarningDialogProps {
  overlaps: MarketMoveOverlapResponse[];
}

const getStatusMessage = (status: RunStatus) => {
  switch (status) {
    case RunStatus.INPROGRESS:
      return "currently in progress";
    case RunStatus.SIGNEDOFF:
      return "signed off";
    case RunStatus.EXECUTED:
      return "executed";
    default:
      return "exists";
  }
};

export const OverlapWarningDialog: React.FC<OverlapWarningDialogProps> = ({
  overlaps,
}) => {
  const [expanded, setExpanded] = useState(false);
  const products = getUniqueProducts();
  const getProductName = (productId: string) => {
    const product = products.find((p) => p.id === productId);
    return product?.name || productId;
  };

  // Flatten the overlaps array to show all market move runs
  const flattenedOverlaps = overlaps.flatMap((overlap) =>
    overlap.overlaps.map((run) => ({
      storeId: overlap.storeId,
      productId: overlap.productId,
      marketMoveRun: run,
    }))
  );

  const initialItems = flattenedOverlaps.slice(0, 2);
  const remainingItems = flattenedOverlaps.slice(2);
  const showExpandButton = flattenedOverlaps.length > 2;

  const renderOverlapItem = (
    overlap: {
      storeId: string;
      productId: string;
      marketMoveRun: {
        marketMoveId: number;
        marketMoveRunId: number;
        marketMoveName: string;
        startDate: string;
        endDate: string;
        runStatus: RunStatus;
      };
    },
    index: number
  ) => (
    <ListItem key={`${overlap.storeId}-${overlap.productId}-${index}`}>
      <ListItemText
        primary={
          <Typography>
            Store{" "}
            <Box component="span" fontWeight="bold">
              {overlap.storeId}
            </Box>{" "}
            - Product{" "}
            <Box component="span" fontWeight="bold">
              {getProductName(overlap.productId)} ({overlap.productId})
            </Box>
          </Typography>
        }
        secondary={
          <Typography variant="body2">
            Overlaps with{" "}
            <Box
              component="span"
              fontWeight="bold"
              sx={{
                borderBottom: "1px dotted",
              }}
            >
              {overlap.marketMoveRun.marketMoveName} (#
              {overlap.marketMoveRun.marketMoveId})
            </Box>{" "}
            (
            <Box component="span" fontWeight="bold">
              {getStatusMessage(overlap.marketMoveRun.runStatus)}
            </Box>
            ) from{" "}
            <Box component="span" fontWeight="bold">
              {formatISODate(new Date(overlap.marketMoveRun.startDate))}
            </Box>{" "}
            to{" "}
            <Box component="span" fontWeight="bold">
              {formatISODate(new Date(overlap.marketMoveRun.endDate))}
            </Box>
          </Typography>
        }
      />
    </ListItem>
  );

  return (
    <Box>
      <Typography
        variant="h6"
        gutterBottom
        color="error.main"
        sx={{ fontWeight: "bold" }}
      >
        Overlapping Market Moves Detected
      </Typography>
      <Typography color="text.secondary" mb={2}>
        The following stores have overlapping market moves during the selected
        date range:
      </Typography>
      <List>
        {initialItems.map((overlap, index) =>
          renderOverlapItem(overlap, index)
        )}
        {showExpandButton && (
          <Collapse in={expanded}>
            {remainingItems.map((overlap, index) =>
              renderOverlapItem(overlap, index + 2)
            )}
          </Collapse>
        )}
      </List>
      {showExpandButton && (
        <Box display="flex" justifyContent="center" mb={2}>
          <Button
            onClick={() => setExpanded(!expanded)}
            size="small"
            sx={{ mt: 1 }}
          >
            {expanded ? "Show Less" : `Show ${remainingItems.length} More`}
          </Button>
        </Box>
      )}
      <Typography color="error.main" mt={2}>
        To proceed, please adjust your date range or store selection to avoid
        overlaps with signed off or inprogress market moves.
      </Typography>
      <Typography color="text.secondary" mt={1} fontSize="0.875rem">
        Market moves cannot overlap for the same store and product combination.
      </Typography>
    </Box>
  );
};
