import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { PriceDataContext } from "./PriceDataContext";
import { IAllHistory } from "../models/history/IProductHistory";
import { CompetitorPriceDataContext } from "./CompetitorPriceDataContext";
import { useHistoryData } from "../hooks/useHistoryData";

export interface ProductEntry {
  auditDate: Date;
  historyType: string;
  message: string;
  changeType?: string;
  userName?: string;
  data: any;
}

interface HistoryTabData {
  historyData: IAllHistory;
  dateRange: { fromDate: Date; toDate: Date };
  setDateRange: (dateRange: { fromDate: Date; toDate: Date }) => void;
  isLoading: boolean;
  displayCount: number;
  increaseDisplayCount: () => void;
}

export const HistoryTabDataContext = createContext<HistoryTabData>({
  historyData: null,
  dateRange: { fromDate: null, toDate: null },
  setDateRange: () => {},
  isLoading: false,
  displayCount: 50,
  increaseDisplayCount: () => {},
});

const INITIAL_DISPLAY_COUNT = 50;
const DISPLAY_INCREMENT = 50;

export const HistoryTabDataProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [displayCount, setDisplayCount] = useState(INITIAL_DISPLAY_COUNT);

  const [dateRange, setDateRange] = useState<{ fromDate: Date; toDate: Date }>(
    () => {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);
      return {
        fromDate: thirtyDaysAgo,
        toDate: today,
      };
    }
  );

  const priceDataContext = useContext(PriceDataContext);
  const { priceData, seiId } = priceDataContext;
  const { initialData } = useContext(CompetitorPriceDataContext);

  const { data: historyData, isLoading } = useHistoryData({
    seiId,
    productIds: priceData?.map((data) => data.productId) || [],
    competitorImportCodes: initialData?.map((data) => data.importCode) || [],
    fromDate: dateRange.fromDate,
    toDate: dateRange.toDate,
    enabled: Boolean(priceData?.length && initialData?.length && seiId),
  });

  // Reset display count when switching products/stores
  useEffect(() => {
    setDisplayCount(INITIAL_DISPLAY_COUNT);
  }, [priceData]);

  const increaseDisplayCount = useCallback(() => {
    setDisplayCount((prevCount) => prevCount + DISPLAY_INCREMENT);
  }, []);

  const contextValue = useMemo(
    () => ({
      historyData,
      dateRange,
      setDateRange,
      isLoading,
      displayCount,
      increaseDisplayCount,
    }),
    [historyData, dateRange, isLoading, displayCount, increaseDisplayCount]
  );

  return (
    <HistoryTabDataContext.Provider value={contextValue}>
      {children}
    </HistoryTabDataContext.Provider>
  );
};
