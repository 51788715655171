import KpiDashboard from "./KpiDashboard";
import { mockCustomProps } from "./mock/KpiDashboardMockups";

export default function App(singleSpaMountProps) {
  console.log("visops-tableau-kpi: customProps", singleSpaMountProps);
  const isLocalHost = window?.location?.hostname === "localhost";

  const openAppWithMockProps = false;

  console.log(
    "🚀 ~ App ~ isLocalHost:",
    isLocalHost,
    "openAppWithMockProps:",
    openAppWithMockProps
  );
  return KpiDashboard(
    isLocalHost && openAppWithMockProps
      ? mockCustomProps
      : singleSpaMountProps.customProps
  );
}
