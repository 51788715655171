import React from "react";
import { AppBar, Badge, Tab, Tabs, Tooltip } from "@mui/material";
import { TabInfo } from "../interfaces/ITabs";

interface SETabsProps<T extends TabInfo> {
  tabs: T[];
  tabValue: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  badgeCounts?: { [key: string]: number };
  tabEditMode: {
    [key: string]: {
      [rowId: string]: boolean;
    };
  };
}

function a11yProps(index: string) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const SETabs = <T extends TabInfo>({
  tabs,
  tabValue,
  handleTabChange,
  badgeCounts = {},
  tabEditMode,
}: SETabsProps<T>) => {
  return (
    <div>
      <AppBar position="static" color="success">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          orientation="horizontal"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {tabs.map((tab) => {
            const isDisabled =
              Object.keys(tabEditMode).length > 0 && !tabEditMode[tab.id];
            return (
              <Tab
                disabled={isDisabled}
                key={tab?.id}
                label={
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      color: "#FFFFFF",
                    }}
                  >
                    {tab.name}
                    {badgeCounts[tab.id] > 0 && (
                      <Badge
                        badgeContent={badgeCounts[tab.id]}
                        color="error"
                        sx={{
                          position: "absolute",
                          top: -6,
                          right: -10,
                          width: 18,
                          height: 18,
                          minWidth: 18,
                          fontSize: "0.75rem",
                        }}
                        overlap="circular"
                      />
                    )}
                  </div>
                }
                {...a11yProps(tab.id)}
                sx={{
                  minWidth: "200px",
                }}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </div>
  );
};

export default SETabs;
