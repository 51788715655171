import { useEffect, useState } from "react";
import { SENumberInput, SEPriceInput } from "@7eleven/visops-react-ui-kit";

const SERangeValidationTextField = ({
  label,
  field,
  value,
  handleInputChange,
  min,
  max,
  disabled,
}) => {
  const [error, setError] = useState("");
  const [tooltipMessage, setToolTipMessage] = useState("");
  useEffect(() => {
    setToolTipMessage(
      disabled ? "Exit the table's edit mode to enable global editing." : ""
    );
  }, [disabled]);
  const handleValueChange = (inputValue) => {
    if (inputValue == value) {
      return;
    }

    // Allow empty string to clear the input
    if (inputValue === "") {
      setError("");
      handleInputChange(field, null); // Or handle it as needed, e.g., set to null
      return;
    }

    const newValue = Number(inputValue);
    if ((newValue < min || newValue > max) && newValue !== 0) {
      setError(`Value must be between ${min} and ${max}`);
    } else {
      setError("");
    }

    if ((newValue >= min && newValue <= max) || newValue === 0) {
      handleInputChange(field, inputValue);
    }
  };

  const handleTooltipChange = (show: boolean, message: string) => {
    setToolTipMessage(message);
    // setError(message);
  };

  return (
    <SENumberInput
      label={label}
      fullWidth
      value={value ?? ""}
      key={field}
      min={0}
      max={1}
      step={0.01}
      precision={2}
      tooltipMessage={tooltipMessage}
      isTooltipControlled={false}
      handleChange={handleValueChange}
      onTooltipChange={handleTooltipChange}
      variant="outlined"
      size="medium"
      // error={!!error}
      disabled={disabled}
      helperText=" "
    />
  );
};

export default SERangeValidationTextField;
