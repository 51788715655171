import { useQuery } from "@tanstack/react-query";
import { getHistoryData } from "../api/getHistoryApi";
import { IAllHistory } from "../models/history/IProductHistory";

function roundDownToMinute(date: Date): Date {
  const roundedDate = new Date(date);
  roundedDate.setSeconds(0);
  roundedDate.setMilliseconds(0);
  return roundedDate;
}

function roundUpToMinute(date: Date): Date {
  const roundedDate = new Date(date);
  roundedDate.setSeconds(59);
  roundedDate.setMilliseconds(999);
  return roundedDate;
}

interface UseHistoryDataParams {
  seiId: string;
  productIds: string[];
  competitorImportCodes: string[];
  fromDate: Date;
  toDate: Date;
  enabled?: boolean;
}

export const useHistoryData = ({
  seiId,
  productIds,
  competitorImportCodes,
  fromDate: rawFromDate,
  toDate: rawToDate,
  enabled = true,
}: UseHistoryDataParams) => {
  // Handle date transformations
  let fromDate = rawFromDate;
  let toDate = rawToDate;

  if (!toDate) {
    toDate = new Date();
  }

  if (!fromDate) {
    fromDate = new Date(new Date().setDate(new Date().getDate() - 30));
  }

  // Round the dates
  const roundedFromDate = roundDownToMinute(fromDate);
  const roundedToDate = roundUpToMinute(toDate);
  console.debug("Rounded date values:", {
    roundedFromDate: roundedFromDate.toISOString(),
    roundedToDate: roundedToDate.toISOString(),
  });

  return useQuery<IAllHistory>({
    queryKey: [
      "historyData",
      seiId,
      productIds,
      competitorImportCodes,
      roundedFromDate,
      roundedToDate,
    ],
    queryFn: async () => {
      return getHistoryData(
        seiId,
        productIds,
        competitorImportCodes,
        roundedFromDate,
        roundedToDate,
        () => {}
      );
    },
    enabled:
      enabled &&
      Boolean(seiId && productIds?.length && competitorImportCodes?.length),
    staleTime: 1 * 60 * 1000, // Consider data stale after 1 minutes
    cacheTime: 1 * 60 * 1000, // Keep unused data in cache for 1 minute
  });
};
