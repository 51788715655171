import { AxiosError } from "axios";
import { useMemo } from "react";
import {
  MarketMoveOverlapResponse,
  StoreProduct,
} from "../api/marketMoveRunApi";
import { useCheckMarketMoveOverlap } from "./marketMoveRunApiHooks";
import { useMarketMoveMetaDataMethods } from "../store/formStore";

interface UseOverlapCheckReturn {
  isChecking: boolean;
  overlaps: MarketMoveOverlapResponse[] | undefined;
  isError: boolean;
  error: AxiosError | null;
}

export const useOverlapCheck = (): UseOverlapCheckReturn => {
  const { watch } = useMarketMoveMetaDataMethods();

  // Destructure with default values to prevent undefined errors
  const [startDate, endDate, storeList, productConfigList, marketMoveId] =
    watch([
      "startDate",
      "endDate",
      "storeList",
      "productConfigList",
      "marketMoveId",
    ]) ?? [];

  console.debug("[useOverlapCheck] Form values:", {
    startDate,
    endDate,
    storeListLength: storeList?.length,
    productConfigListLength: productConfigList?.length,
    marketMoveId,
  });

  // Convert to StoreProduct array checking store-product pair overlaps
  const storeProducts: StoreProduct[] = useMemo(() => {
    if (!Array.isArray(storeList) || !Array.isArray(productConfigList)) {
      console.warn("[useOverlapCheck] Invalid storeList or productConfigList", {
        isStoreListArray: Array.isArray(storeList),
        storeListLength: storeList?.length,
        productConfigListLength: productConfigList?.length,
      });
      return [];
    }

    // Get all valid stores
    const validStores = storeList.filter((store) =>
      Boolean(
        store &&
          typeof store.storeId === "string" &&
          store.flag !== "REMOVE" &&
          store.includeFlag
      )
    );

    // Create store-product pairs for each store and each product
    const products = validStores.flatMap((store) =>
      productConfigList.map((config) => ({
        storeId: store.storeId,
        productId: config.productId,
      }))
    );

    console.info("[useOverlapCheck] Generated store-product pairs:", {
      storeCount: validStores.length,
      productCount: productConfigList.length,
      outputLength: products.length,
    });
    return products;
  }, [storeList, productConfigList]);

  // Validate and prepare params with strict type checking
  const params = useMemo(() => {
    if (
      !startDate ||
      !endDate ||
      !Array.isArray(storeProducts) ||
      storeProducts.length === 0
    ) {
      console.warn("[useOverlapCheck] Invalid params for overlap check", {
        hasStartDate: Boolean(startDate),
        hasEndDate: Boolean(endDate),
        storeProductsLength: storeProducts?.length,
      });
      return null;
    }

    const preparedParams = {
      startDate: String(startDate),
      endDate: String(endDate),
      storeProducts,
    };
    console.debug(
      "[useOverlapCheck] Prepared params for overlap check:",
      preparedParams
    );
    return preparedParams;
  }, [startDate, endDate, storeProducts]);

  const { data, isError, error, isFetching } =
    useCheckMarketMoveOverlap(params);

  if (isError) {
    console.error("[useOverlapCheck] Error checking overlaps:", error);
  }

  // Filter out current market move from overlaps with additional safety checks
  const filteredOverlaps = useMemo(() => {
    if (!Array.isArray(data) || !marketMoveId) {
      console.warn(
        "[useOverlapCheck] Invalid data or marketMoveId for filtering",
        {
          isDataArray: Array.isArray(data),
          hasMarketMoveId: Boolean(marketMoveId),
        }
      );
      return data;
    }

    const filtered = data
      .filter((overlap): overlap is MarketMoveOverlapResponse =>
        Boolean(
          overlap &&
            typeof overlap.storeId === "string" &&
            typeof overlap.productId === "string" &&
            Array.isArray(overlap.overlaps)
        )
      )
      .map((overlap) => ({
        ...overlap,
        marketMoveRun: (overlap.overlaps ?? []).filter(
          (run) =>
            Boolean(run) &&
            typeof run === "object" &&
            "marketMoveId" in run &&
            run.marketMoveId &&
            run.marketMoveId.toString() !== marketMoveId.toString()
        ),
      }))
      .filter(
        (overlap) =>
          Array.isArray(overlap.marketMoveRun) &&
          overlap.marketMoveRun.length > 0
      );

    console.info("[useOverlapCheck] Filtered overlaps:", {
      inputLength: data.length,
      outputLength: filtered.length,
    });
    return filtered;
  }, [data, marketMoveId]);

  return {
    isChecking: isFetching,
    overlaps: filteredOverlaps,
    isError,
    error: error || null,
  };
};
