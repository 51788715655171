import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import SERangeValidationTextField from "../core/SERangeValidationTextField";
import GlobalMinMaxPrice from "../core/GlobalMinMaxPrice";

// Define the function to render global input fields based on column configurations
const renderGlobalInputField = ({
  label,
  type,
  options,
  default_val,
  value,
  handleChange,
  field,
  min,
  max,
  step,
  globalValidationErrors,
  disabled,
}) => {
  switch (type) {
    case "singleSelect":
      return (
        <Tooltip
          title={
            disabled
              ? "Exit the table's edit mode to enable global editing."
              : ""
          }
          followCursor
        >
          <FormControl fullWidth variant="outlined">
            <InputLabel
              id={`${field}-label`}
              sx={{
                whiteSpace: "nowrap", // Prevent label from wrapping
                overflow: "hidden", // Hide overflow
                textOverflow: "ellipsis", // Show ellipsis for overflow
                maxWidth: "230px", // Set a max width for the label
              }}
              disabled={disabled}
            >{`Select ${label}`}</InputLabel>
            <Select
              labelId={`${field}-label`}
              value={value || ""} // Ensure value is an empty string if no selection
              onChange={(e) => handleChange(field, e.target.value)}
              label={`Select ${label}`} // Ensure the label is associated with the select
              onFocus={() =>
                (document.getElementById(`${field}-label`).style.maxWidth =
                  "150px")
              }
              onBlur={() =>
                (document.getElementById(`${field}-label`).style.maxWidth =
                  "230px")
              }
              disabled={disabled}
            >
              <MenuItem value="">
                <em>{default_val}</em>{" "}
                {/* Default value displayed when no selection */}
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option.value.toString()} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {globalValidationErrors?.[field] || " "}
            </FormHelperText>
          </FormControl>
        </Tooltip>
      );

    case "number":
      return (
        <GlobalMinMaxPrice
          disabled={disabled}
          field={field}
          label={label}
          value={value}
          onChange={handleChange}
          min={min}
          step={step}
          error={Boolean(globalValidationErrors?.[field])}
          helperText={globalValidationErrors?.[field] || " "}
        />
      );
    case "range":
      return (
        <SERangeValidationTextField
          field={field}
          handleInputChange={handleChange}
          label={label}
          max={max}
          min={min}
          value={value}
          key={field}
          disabled={disabled}
        />
      );

    default:
      // Fallback for text or other types
      return (
        <TextField
          key={field}
          label={`Global ${label}`}
          value={value || ""}
          onChange={(e) => handleChange(field, e.target.value)}
          fullWidth
          disabled={disabled}
        />
      );
  }
};

export default renderGlobalInputField;
