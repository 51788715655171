import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useShallow } from "zustand/react/shallow";
import {
  useCreateMarketMove,
  useGetMMList,
  useUpdateMarketMove,
} from "../../../hooks/marketMoveMetaDataApiHooks";
import { useOverlapCheck } from "../../../hooks/useOverlapCheck";
import useShouldSave from "../../../hooks/useShouldSave";
import { RunStatus } from "../../../interfaces/enums";
import { MarketMoveMetaDataSchema } from "../../../schema/schemas";
import {
  useMarketMoveMetaDataMethods,
  useMarketMoveRunMethods,
} from "../../../store/formStore";
import useMarketMoveStore from "../../../store/marketMoveStore";
import useDialogStore from "../../../store/useDialogStore";
import { generateDefaultMarketMove } from "../../../utils/marketMoveUtils";
import { EndDateUpdateBox } from "../../shared/dialogViews/EndDateUpdate";
import { OverlapWarningDialog } from "../../shared/dialogViews/OverlapWarningDialog";
import GlobalDialog from "../../shared/GlobalDialog";
import LifeCycleStepper from "../../shared/LifeCycleStepper";
import ZonedDateTimePicker from "../../shared/ZonedDateTimePicker";
import MarketMoveNameField from "./MarketMoveNameField";
import StoreListDialog from "./storeList/StoreListDialog";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:00";

const MarketMoveSettings: React.FC = () => {
  const { isError, isLoading, error } = useGetMMList();
  const {
    setSelectedMarketMoveId,
    incrementPreviewDetailsFetchReq,
    incrementMMMetaDataSaveReq,
  } = useMarketMoveStore();

  const { openGlobalDialog, closeGlobalDialog } = useDialogStore(
    useShallow((state) => ({
      openGlobalDialog: state.openDialog,
      closeGlobalDialog: state.closeDialog,
    }))
  );

  const [openStoreListDialog, setOpenStoreListDialog] = useState(false);

  const createMarketMoveMetaData = useCreateMarketMove();
  const updateMarketMoveMetaData = useUpdateMarketMove();
  const {
    watch: marketMoveRunWatch,
    formState: { defaultValues: marketMoveRunDefaultValues },
  } = useMarketMoveRunMethods();

  const {
    trigger,
    watch: marketMoveMetaDataWatch,
    reset: resetForm,
    control: control,
  } = useMarketMoveMetaDataMethods();

  const runStatus = marketMoveRunWatch("marketMoveRun.runStatus");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showErrorIcon, setShowErrorIcon] = useState(false);

  // Use the custom hook to determine if we should save and get the reason
  const { shouldSave, dirtyFieldsTooltip } = useShouldSave();

  const formValues = marketMoveMetaDataWatch();
  const endDate = formValues.endDate;

  const { isChecking, overlaps } = useOverlapCheck();

  const handleSave = async () => {
    if (overlaps?.length) {
      openGlobalDialog({
        content: <OverlapWarningDialog overlaps={overlaps} />,
        acceptButtonText: "OK",
        isSingleButton: true,
      });
      return;
    }

    if (runStatus === RunStatus.INPROGRESS) {
      openGlobalDialog({
        content: (
          <EndDateUpdateBox
            originalEndDate={marketMoveRunDefaultValues.marketMoveRun?.endDate}
            newEndDate={endDate}
          />
        ),
        acceptCallback: () => handleDialogSave(RunStatus.INPROGRESS),
        acceptButtonText: "Yes, Save",
        discardButtonText: "No, Cancel",
      });
    } else {
      handleDialogSave();
    }
  };

  const { trigger: useFormTrigger } = useMarketMoveMetaDataMethods(); // Use useFormContext to access trigger

  // Watch the endDate field
  const currentMarketMove = useWatch<MarketMoveMetaDataSchema>({ control });

  useEffect(() => {
    if (isError) setOpenSnackbar(true);
  }, [isError]);

  useEffect(() => {
    handleErrorIconVisibility();
  }, [createMarketMoveMetaData.isError, updateMarketMoveMetaData.isError]);

  useEffect(() => {
    if (endDate) {
      useFormTrigger("startDate"); // Trigger validation on startDate when endDate changes
    }
  }, [endDate, useFormTrigger]);

  const handleErrorIconVisibility = () => {
    if (createMarketMoveMetaData.isError || updateMarketMoveMetaData.isError) {
      setShowErrorIcon(true);
      const timer = setTimeout(() => setShowErrorIcon(false), 3000);
      return () => clearTimeout(timer);
    }
  };

  // useEffect(() => {
  //   openGlobalDialog({
  //     title: "Discard Changes",
  //     content: <div>Do you want to discard the changes?</div>,
  //     acceptCallback: handleDiscard,
  //     discardCallback: () => closeGlobalDialog(),
  //     acceptButtonText: "Yes, Discard", // Custom text for accept button
  //     discardButtonText: "No, Keep", // Custom text for discard button
  //     actions: null,
  //   });
  // }, []);

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const handleCancel = () => {
    if (shouldSave) {
      openGlobalDialog({
        content: <div>Do you want to discard the changes?</div>,
        acceptCallback: handleDiscard,
        discardCallback: () => closeGlobalDialog(),
        acceptButtonText: "Yes, Discard", // Custom text for accept button
        discardButtonText: "No, Keep", // Custom text for discard button
        actions: null,
      });
    } else {
      resetForm(null);
    }
  };

  const handleDialogSave = (newStatus: RunStatus = RunStatus.DRAFT) => {
    incrementPreviewDetailsFetchReq(true, () => {
      trigger().then((isValid) => {
        if (isValid) {
          incrementMMMetaDataSaveReq(newStatus, true);
        } else {
          enqueueSnackbar("Please fix the errors in the form", {
            variant: "error",
          });
        }
      });
    });
    closeGlobalDialog();
  };

  const handleDiscard = () => {
    resetForm(generateDefaultMarketMove());
    setSelectedMarketMoveId(null);
    closeGlobalDialog();
  };

  const getDateDifferenceInDays = (date1: Date, date2: Date): number => {
    const timeDiffInMs = Math.abs(date2.getTime() - date1.getTime());
    const msPerDay = 1000 * 60 * 60 * 24;
    const dayDiff = timeDiffInMs / msPerDay;
    return dayDiff;
  };

  const [originalStartDate, setOriginalStartDate] = useState(null);
  const [originalEndDate, setOriginalEndDate] = useState(null);

  useEffect(() => {
    setOriginalStartDate(currentMarketMove.startDate);
    setOriginalEndDate(currentMarketMove.endDate);
  }, [currentMarketMove.marketMoveId]);

  const warnUserIfDateRangeExceedsLimit = (value, name) => {
    const maxDays = 30;

    let startDate = name == "startDate" ? value : new Date(originalStartDate);
    let endedDate = name == "endDate" ? value : new Date(originalEndDate);

    let oldDate =
      name == "startDate"
        ? dayjs(originalStartDate).format(DATE_TIME_FORMAT)
        : dayjs(originalEndDate).format(DATE_TIME_FORMAT);
    const newDate =
      name == "startDate"
        ? dayjs(startDate).format(DATE_TIME_FORMAT)
        : dayjs(endedDate).format(DATE_TIME_FORMAT);

    const daysPassed = getDateDifferenceInDays(startDate, endedDate);
    const displayName = name == "startDate" ? "Start" : "End";

    if (daysPassed < maxDays || oldDate == newDate) {
      return;
    }

    console.debug("Warning triggered.  Days passed = " + daysPassed);

    openGlobalDialog({
      content: (
        <Box sx={{ padding: 2, textAlign: "center" }}>
          <Typography variant="h6" gutterBottom>
            Update Date Range
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 2,
            }}
          >
            <Box>
              <Typography variant="subtitle1" sx={{ color: "#6c757d" }}>
                Current {displayName} Date:
              </Typography>
              <Typography variant="body2" sx={{ color: "primary" }}>
                {oldDate}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ color: "#6c757d" }}>
                New {displayName} Date:
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "primary" }}
                dangerouslySetInnerHTML={{ __html: newDate }}
              />
            </Box>
          </Box>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Warning! The selected date range exceeds {maxDays} days!
            <Typography>Are you sure you want to proceed?</Typography>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 2,
            }}
          ></Box>
          <Typography variant="caption" sx={{ color: "#dc3545" }}>
            Please ensure the new date is correct before proceeding.
          </Typography>
        </Box>
      ),
      actions: (
        <>
          <Button
            onClick={closeGlobalDialog}
            color="primary"
            sx={{ color: "green" }}
          >
            OK
          </Button>
        </>
      ),
    });
  };

  const createDateField = (
    label: string,
    valueKey: "startDate" | "endDate"
  ) => (
    <ZonedDateTimePicker
      name={valueKey}
      onAccept={(value, name) => {
        warnUserIfDateRangeExceedsLimit(value, name);
      }}
    />
  );

  const renderHeader = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={1}
    >
      <Tooltip title={dirtyFieldsTooltip}>
        <span>
          <IconButton
            aria-label="Save changes"
            sx={{ color: shouldSave ? "green" : "gray" }}
            disabled={
              !shouldSave ||
              createMarketMoveMetaData.isLoading ||
              updateMarketMoveMetaData.isLoading
            }
            onClick={handleSave}
          >
            {createMarketMoveMetaData.isLoading ||
            updateMarketMoveMetaData.isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : showErrorIcon ? (
              <CloseIcon sx={{ color: "red" }} />
            ) : (
              <SaveIcon />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <LifeCycleStepper runStatus={runStatus} />
      <Tooltip title="Undo changes">
        <span>
          <IconButton
            aria-label="Undo changes"
            sx={{ color: shouldSave ? "red" : "gray" }}
            onClick={handleCancel}
            disabled={
              !shouldSave ||
              isLoading ||
              createMarketMoveMetaData.isLoading ||
              updateMarketMoveMetaData.isLoading
            }
          >
            <UndoIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );

  const renderContent = () => (
    <>
      {/* <ErrorDisplay errors={marketMoveMetaDataFormState.errors} /> */}

      <Grid container>
        <Grid item xs={12} mb={2}>
          <MarketMoveNameField />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          {createDateField("Start Date (CST)", "startDate")}
        </Grid>
        <Grid item xs={3}>
          {createDateField("End Date (CST)", "endDate")}
        </Grid>
        <Grid item xs={6}>
          <StoreListDialog
            open={openStoreListDialog}
            setOpen={setOpenStoreListDialog}
            onClose={() => setOpenStoreListDialog(false)}
          />
        </Grid>
      </Grid>
    </>
  );

  const renderSkeleton = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rectangular" height={56} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rectangular" height={56} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={200} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid item xs={12} className="marketMoveSettings">
        <Box borderRadius={2}>
          {renderHeader()}
          {isLoading ? renderSkeleton() : renderContent()}
        </Box>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error?.message || "An error occurred while loading market moves."}
        </Alert>
      </Snackbar>
      <GlobalDialog />
      {isChecking && (
        <CircularProgress
          size={20}
          sx={{ position: "absolute", right: 16, top: 16 }}
        />
      )}
    </>
  );
};

export default MarketMoveSettings;
