import { useQuery } from "@tanstack/react-query";
import { ProductStrategy } from "../types/PriceReview.types";
import apiClient from "../util/apiClient";
import { formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";

type Rule = {
  type: string;
  linkedProduct: string;
  spread: number;
};

type CompetitorOffset = {
  competitorId: string;
  offset: number;
};

type Strategy = {
  id: string;
  mapping: {
    storeId: string;
    productId: string;
  };
  rules: Array<Rule>;
  competitorOffsets: Array<CompetitorOffset>;
};

export type GetStrategyParams = {
  storeId: string;
  productId: string;
};

const fetchStrategy = async (params: GetStrategyParams): Promise<Strategy> => {
  const serviceUrl = await formServiceUrl(
    constants.urlConstants.priceGenPriceAdminMaster.name,
    constants.urlConstants.priceGenPriceAdminMaster.getStrategy
  );

  try {
    const response = await apiClient.get(serviceUrl, { params: params });
    console.debug("Fetch Strategy RESULT: ", response);
    return response.data;
  } catch (error) {
    console.warn(
      "Error fetching strategy (may not exist). Creating new one... ",
      error
    );
    return {
      id: null,
      mapping: {
        storeId: params.storeId,
        productId: params.productId,
      },
      rules: [],
      competitorOffsets: [],
    };
  }
};

export const useGetStrategy = (params: GetStrategyParams) => {
  const QUERY_KEY = [
    constants.urlConstants.priceGenPriceAdminMaster.getStrategyQueryKey,
  ];

  return useQuery<Strategy, Error>({
    queryKey: QUERY_KEY,
    queryFn: () => fetchStrategy(params),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  });
};

// New function for fetching all strategies for a store
export const useGetProductStrategies = (storeId: string) => {
  const QUERY_KEY = [
    constants.urlConstants.priceGenPriceAdminMaster.getStrategyByStoreQueryKey,
    storeId,
  ];

  return useQuery<ProductStrategy[]>({
    queryKey: QUERY_KEY,
    queryFn: async () => {
      const serviceUrl = await formServiceUrl(
        constants.urlConstants.priceGenPriceAdminMaster.name,
        constants.urlConstants.priceGenPriceAdminMaster.getStrategyByStore
      );
      const response = await apiClient.get(serviceUrl, {
        params: { storeId },
      });
      return response.data;
    },
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    retry: 3,
  });
};
