import { SetPriceDataType, StatusType } from "../types/PriceReview.types";
import PriceDataModel from "../models/priceView/PriceData.model";

function roundToThreeDecimalPlaces(num: number) {
  return Number(num.toFixed(3));
}

class PriceDataUtilities {
  static changeProposedPriceStatus(
    setPriceData: SetPriceDataType,
    newStatus: StatusType,
    productId: string
  ) {
    setPriceData((prevPriceData) => {
      let isParent = false;
      let parentItem = null;
      
      for (const item of prevPriceData) {
        if (item.parentProductId === productId) isParent = true;
        if (item.productId === productId) parentItem = item;
        if (isParent && parentItem) break;
      }

      const isUndoingParent = isParent && parentItem?.status === newStatus;

      return prevPriceData.map((dataItem) => {
        const isClickedProduct = dataItem.productId === productId;
        const isLinkedProduct = isParent && dataItem.parentProductId === productId;

        if (!isClickedProduct && !isLinkedProduct) {
          return dataItem;
        }

        const ret = dataItem.clone();

        if (isClickedProduct) {
          if (newStatus === "APPROVED" && dataItem.status === "APPROVED" && dataItem.isManualPriceSet) {
            ret.proposedPrice = dataItem.priceData.latestPriceGenPrice;
            ret.status = null;
          } else {
            ret.status = dataItem.status === newStatus ? null : newStatus;
          }
        }
        else if (isLinkedProduct && !isUndoingParent) {
          // Only propagate status to linked products if not undoing parent action
          ret.status = newStatus;
        }

        return ret;
      });
    });
  }

  static changeProposedPriceStatusForAll(
    setPriceData: SetPriceDataType,
    newStatus: StatusType
  ) {
    setPriceData((prevPriceData) => {
      const allMatchStatus = prevPriceData.every((dataItem) => dataItem.status === newStatus);
      
      return prevPriceData.map((dataItem) => {
        const newStatusValue = allMatchStatus ? null : (!dataItem.disabled ? newStatus : null);
        if (newStatusValue === dataItem.status) return dataItem;
        
        const ret = dataItem.clone();
        ret.status = newStatusValue;
        return ret;
      });
    });
  }

  static adjustProposedPrice(
    setPriceData: SetPriceDataType,
    increment: boolean,
    productId: string
  ) {
    setPriceData((prevPriceData) => {
      return prevPriceData.map((dataItem) => {
        if (dataItem.productId !== productId) return dataItem;

        const adjustmentAmount = increment ? 0.01 : -0.01;
        const newPrice = roundToThreeDecimalPlaces(
          dataItem.proposedPrice + adjustmentAmount
        );
        if (newPrice === dataItem.proposedPrice) return dataItem;

        const ret = dataItem.clone();
        ret.proposedPrice = newPrice;
        ret.status = newPrice === dataItem.priceData.latestPriceGenPrice ? null : "APPROVED";
        return ret;
      });
    });
  }

  static adjustProposedPriceForAll(
    setPriceData: SetPriceDataType,
    increment: boolean
  ) {
    setPriceData((prevPriceData) => {
      return prevPriceData.map((dataItem) => {
        const adjustmentAmount = increment ? 0.01 : -0.01;
        const newPrice = roundToThreeDecimalPlaces(
          dataItem.proposedPrice + adjustmentAmount
        );
        if (newPrice === dataItem.proposedPrice) return dataItem;

        const ret = dataItem.clone();
        ret.proposedPrice = newPrice;
        ret.status = "APPROVED";
        return ret;
      });
    });
  }

  static updateProposedPrice(
    setPriceData: SetPriceDataType,
    newPrice: number,
    productId: string
  ) {
    setPriceData((prevPriceData) => {
      return prevPriceData.map((dataItem) => {
        if (dataItem.productId !== productId || newPrice === dataItem.proposedPrice) {
          return dataItem;
        }
        const ret = dataItem.clone();
        ret.proposedPrice = newPrice;
        ret.status = "APPROVED";
        return ret;
      });
    });
  }

  static setProposedPriceStatusFromProposedPrices(
    setPriceData: SetPriceDataType,
    proposedPrices: IProposedPrice[]
  ) {
    setPriceData((prevPriceData) => {
      return prevPriceData.map((dataItem) => {
        const proposedPrice = proposedPrices.find(
          (p) => p.productId === dataItem.productId
        );
        if (!proposedPrice) return dataItem;

        const ret = dataItem.clone();
        ret.priceData.latestPriceGenPriceStatus = proposedPrice.status;
        ret.priceData.latestPriceGenPrice = proposedPrice.proposedPrice;
        ret.priceData.latestPriceGenPriceEffectiveDate = proposedPrice.effectiveDate;
        ret.priceData.latestPriceGenId = proposedPrice.priceGenId;
        ret.priceData.latestPriceGenModifiedBy = proposedPrice.modifiedBy;
        return ret;
      });
    });
  }

  static areAnyPricesFinalized(priceData: PriceDataModel[]) {
    return priceData.some((dataItem) => dataItem.status !== null);
  }
}

export default PriceDataUtilities;
