import {
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  capitalize
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { PriceMode, RunStatus } from "../../interfaces/enums";
import {
  MarketMoveProductConfigSchema
} from "../../schema/schemas";
import { useMarketMoveMetaDataMethods } from "../../store/formStore";
import useGeneralStore from "../../store/generalStore";
import useMarketMoveStore from "../../store/marketMoveStore";
import ProductSettingsTable from "./ProductSettingsTable";

const ProductSettingsContainer: React.FC = () => {
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const { uniqueProducts, elasticData } = useGeneralStore();
  const { disableEditing } = useMarketMoveStore();
  const  {watch, setValue} = useMarketMoveMetaDataMethods()
  const [storeList, productConfigList, runStatus] = watch(["storeList", "productConfigList", "runStatus"])

  const addedProducts = useMemo(
    () => productConfigList || [],
    [productConfigList]
  );

  const workingProducts = useMemo(() => {
    // Get enabled store IDs from storeList
    const enabledStoreIds = storeList.map(store => store.storeId);
    
    // Get all product IDs from enabled stores' elastic data
    const productIds = new Set(
      elasticData
        ?.filter(store => enabledStoreIds.includes(store.seiid))
        ?.flatMap(store => store.proposedPriceStatus?.proposedPriceStatusByProduct?.map(p => p.productId) || [])
    );

    return uniqueProducts.filter(product => productIds.has(product.id));
  }, [storeList, elasticData, uniqueProducts]);


  const addProduct = (productId: string) => {
    if (!productId) return;
    const newProduct: MarketMoveProductConfigSchema = {
      productId: productId,
      excludePriceBelow: null,
      excludePriceAbove: null,
      priceMode: PriceMode.INCREMENTAL,
      priceIncrement: 10,
      absPrice: null,
      applyLinkedProducts: true,
      baseMinPrice: null,
    };
    setValue("productConfigList", [...addedProducts, newProduct], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setSelectedProduct("");
  };

  const deleteProduct = (product: string) => {
    const updatedProducts = addedProducts.filter(
      (p) => p.productId !== product
    );
    setValue("productConfigList", updatedProducts, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const availableProducts = useMemo(() => {
    const filtered = workingProducts
      .filter(
        (product) => !addedProducts.some((p) => p.productId === product.id)
      )
      .sort((a, b) => a.id.localeCompare(b.id));
    return filtered;
  }, [workingProducts, addedProducts]);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedProduct(event.target.value);
  };

  const renderProductList = () => (
    <ProductSettingsTable deleteProduct={deleteProduct} />
  );

  const ProductSelect: React.FC = () => (
    <Select
      value={selectedProduct}
      onChange={(event) => {
        const selectedValue = event.target.value;
        handleSelectChange(event);
        addProduct(selectedValue);
      }}
      displayEmpty
      fullWidth
      disabled={runStatus === RunStatus.INPROGRESS || disableEditing}
      size="small"
      sx={{ "& .MuiSelect-select": { textAlign: "center" } }}
    >
      <MenuItem value="" sx={{ justifyContent: "center" }}>
        {workingProducts.length ? "Select a product" : "Please add stores"}
      </MenuItem>
      {availableProducts.map((product) => (
        <MenuItem key={product.id} value={product.id}>
          {product?.id} - {capitalize(product?.name)}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <ProductSelect />
        </Grid>
        <Grid item xs={9}>
          {renderProductList()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductSettingsContainer;
