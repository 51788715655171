import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { SENumberInput } from "@7eleven/visops-react-ui-kit";

const MinMaxChangeValidationConfig = ({ validatorData, onChange }) => {
  const {
    register,
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext();

  const handleTooltipChange = (show: boolean, message: string) => {};
  return (
    <div>
      <Box component="form" className="validatorConfigPanel">
        <Controller
          name="MaxIncreaseDecrease.enabled.flag"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              className="enableSwitch"
              control={<Switch {...field} checked={field.value} />}
              label="Enabled"
            />
          )}
        />
        <Controller
          name="MaxIncreaseDecrease.validationData.maxDecrease"
          control={control}
          render={({ field }) => {
            return (
              <SENumberInput
                value={field?.value ?? ""}
                error={
                  !!(errors as any)?.MaxIncreaseDecrease?.validationData
                    ?.maxDecrease
                }
                label="Max Decrease"
                variant="standard"
                min={0}
                max={1}
                step={0.01}
                precision={2}
                isTooltipControlled={false}
                tooltipMessage={
                  (errors as any)?.MaxIncreaseDecrease?.validationData
                    ?.maxDecrease?.message
                }
                onTooltipChange={handleTooltipChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                handleChange={(value) => {
                  const isValid =
                    Number(value) >= 0 && Number(value) <= 1 && !(value == "");
                  if (!isValid) {
                    setError(field?.name, {
                      type: "manual",
                      message: "Value must be between 0 and 1",
                    });
                  } else {
                    clearErrors(field?.name);
                  }
                  field?.onChange(value);
                }}
              />
            );
          }}
        />
        <Controller
          name="MaxIncreaseDecrease.validationData.maxIncrease"
          control={control}
          render={({ field }) => (
            <SENumberInput
              value={field?.value ?? ""}
              error={
                !!(errors as any)?.MaxIncreaseDecrease?.validationData
                  ?.maxIncrease
              }
              label="Max Increase"
              variant="standard"
              min={0}
              max={1}
              step={0.01}
              precision={2}
              isTooltipControlled={false}
              tooltipMessage={
                (errors as any)?.MaxIncreaseDecrease?.validationData
                  ?.maxIncrease?.message
              }
              onTooltipChange={handleTooltipChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              handleChange={(value) => {
                const isValid =
                  Number(value) >= 0 && Number(value) <= 1 && !(value == "");
                if (!isValid) {
                  setError(field?.name, {
                    type: "manual",
                    message: "Value must be between 0 and 1",
                  });
                } else {
                  clearErrors(field?.name);
                }
                field?.onChange(value);
              }}
            />
          )}
        />
      </Box>
      <Typography variant="h1">Action to take on failure condition</Typography>
      <hr />
      <Controller
        name="MaxIncreaseDecrease.onFailAction"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            label="Failure Action"
            error={!!(errors as any)?.MaxIncreaseDecrease?.onFailAction}
          >
            <MenuItem value="BlockPriceGenCycle">Block Price Export</MenuItem>
            <MenuItem value="MaxLimit">Limit Price</MenuItem>
            <MenuItem value="RequireApproval">Require Manual Approval</MenuItem>
          </Select>
        )}
      />
    </div>
  );
};

export default MinMaxChangeValidationConfig;
